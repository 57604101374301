import styled from 'styled-components'
import { FilledButtonAsLink } from '../Button.styles'
import ArrowRight from '../../../public/images/icons/arrow-right.svg'
import { defaultSectionTheme } from '../../../constants'

export const ArrowRightIcon = styled(ArrowRight)`
  margin-left: 12px;
  min-width: 16px;
  height: 14px;

  fill: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.secondaryButton.text?.color
        : theme.sectionTheme
          ? theme.sectionTheme?.secondaryButton?.text?.color
          : defaultSectionTheme.secondaryButton.text?.color};

  & > path {
    transition: .4s;
  }
`

export const ButtonLink = styled(FilledButtonAsLink)`
  color: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.secondaryButton.text?.color
        : theme.sectionTheme
          ? theme.sectionTheme?.secondaryButton?.text?.color
          : defaultSectionTheme.secondaryButton.text?.color};
  padding: 4px 0 !important;
  height: unset !important;
  border-radius: unset !important;
  border-bottom: 2px solid transparent;

  & {
    width: fit-content !important;
    min-width: unset !important;
    flex: 1;
  }

  & > span {
    justify-content: flex-start !important;
    width: fit-content !important;
    min-width: unset !important;
    flex: 1;
  }

  :not([disabled]):hover {
    border-bottom-color: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.ppBlueHover
      : buttonTheme
        ? buttonTheme.secondaryButton.text?.hoverColor
        : theme.sectionTheme
          ? theme.sectionTheme?.secondaryButton?.text?.hoverColor
          : defaultSectionTheme.secondaryButton.text?.hoverColor} !important;
    color: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.ppBlueHover
      : buttonTheme
        ? buttonTheme.secondaryButton.text?.hoverColor
        : theme.sectionTheme
          ? theme.sectionTheme?.secondaryButton?.text?.hoverColor
          : defaultSectionTheme.secondaryButton.text?.hoverColor} !important;
    
    ${ArrowRightIcon} > path {
      fill: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.ppBlueHover
      : buttonTheme
        ? buttonTheme.secondaryButton.text?.hoverColor
        : theme.sectionTheme
          ? theme.sectionTheme?.secondaryButton?.text?.hoverColor
          : defaultSectionTheme.secondaryButton.text?.hoverColor} !important;
    }
  }

  & > span > svg,
  & > svg {
    &, & path {
      fill: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.secondaryButton.text?.color
        : theme.sectionTheme
          ? theme.sectionTheme?.secondaryButton?.text?.color
          : defaultSectionTheme.secondaryButton.text?.color};
    }
  }

  :not([disabled]):hover {
    & > span > svg,
    & > svg {
      &, & path {
        fill: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.secondaryButton.text?.hoverColor
        : theme.sectionTheme
          ? theme.sectionTheme?.secondaryButton?.text?.hoverColor
          : defaultSectionTheme.secondaryButton.text?.hoverColor} !important;
        }
      }
    }
  }
`

export const Button = styled(ButtonLink).attrs({
  as: 'button'
})``

export default { Button, ButtonLink }
