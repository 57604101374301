import styled from 'styled-components'
import { BaseButton } from '../Button.styles'
import type { TextButtonTheme } from '@/interfaces/theme'

export const TextButton = styled(BaseButton)<{ mappedColor: TextButtonTheme }>`
  color: ${({ mappedColor }) => mappedColor?.color};
  padding: 4px 0 !important;
  height: unset !important;
  border-radius: unset !important;
  border-bottom: 2px solid transparent;

  & {
    width: fit-content !important;
    min-width: unset !important;
    flex: 1;
  }

  & > span {
    justify-content: flex-start !important;
    width: fit-content !important;
    min-width: unset !important;
    flex: 1;
  }

  :not([disabled]):hover {
    color: ${({ mappedColor }) => mappedColor?.hoverColor} !important;
    border-bottom-color: ${({ mappedColor }) => mappedColor?.hoverColor};
  }

  & > span > svg,
  & > svg {
    &, & path {
      fill: ${({ mappedColor }) => mappedColor?.color};
    }
  }

  :not([disabled]):hover {
    & > span > svg,
    & > svg {
      &, & path {
        fill: ${({ mappedColor }) => mappedColor?.hoverColor} !important;
      }
    }
  }
`
