import type { DefaultTheme, StyledComponent } from 'styled-components'
import { defaultSectionTheme } from './../../constants'
import type { CustomButtonThemes, ISectionTheme } from '@/interfaces/theme'
import type { NewButtonProps } from '@/interfaces/button.interfaces'
import {
  FilledButton, OutlinedButton, TextButton 
} from './variants'

export const getColor = (
  theme: DefaultTheme,
  variant: string,
  color: string
) => {
  const mappedColor = `${color.toLowerCase()}Button`
  const sestionTheme = (
    theme.sectionTheme?.[
      mappedColor as keyof ISectionTheme
    ] as CustomButtonThemes
  )?.[variant?.toLowerCase() as keyof CustomButtonThemes]

  const defaultTheme = (
    (defaultSectionTheme as ISectionTheme)?.[
      mappedColor as keyof ISectionTheme
    ] as CustomButtonThemes
  )?.[variant?.toLowerCase() as keyof CustomButtonThemes]

  return theme.sectionTheme ? sestionTheme : defaultTheme
}

export const getButtonComponent = (
  variant: NewButtonProps['buttonVariant']
): StyledComponent<any, any, any, any> => {

  switch (variant) {
    case 'Outlined':
      return OutlinedButton
    case 'Filled':
      return FilledButton
    default:
      return TextButton
  }
}

export const getPathnameFromURL = (url: string): string => {
  try {
    const parsedURL = new URL(url)
    return parsedURL.pathname
  } catch {
    return ''
  }
}
