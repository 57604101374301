import styled from 'styled-components'
import { BaseButton } from '../Button.styles'
import type { FilledButtonTheme } from '@/interfaces/theme'

export const FilledButton = styled(BaseButton)<{
  mappedColor: FilledButtonTheme
}>`
  border: 1px solid ${({ mappedColor }) => mappedColor?.backgroundColor};
  background: ${({ mappedColor }) => mappedColor?.backgroundColor};

  color: ${({ mappedColor }) => mappedColor?.color};

  :not([disabled]):hover {
    border: 1px solid ${({ mappedColor }) => mappedColor?.hoverBackgroundColor};
    background: ${({ mappedColor }) => mappedColor?.hoverBackgroundColor};
    color: ${({ mappedColor }) => mappedColor?.color};
  }

  & > span > svg,
  & > svg {
    &, & path {
      fill: ${({ mappedColor }) => mappedColor?.color};
    }
  }

  :not([disabled]):hover {
    & > span > svg,
    & > svg {
      &, & path {
        fill: ${({ mappedColor }) => mappedColor?.color};
      }
    }
  }
`
