import Link from 'next/link'
import Image from 'next/image'
import type { NewButtonProps } from '@/interfaces/button.interfaces'
import {
  forwardRef, useMemo, type MouseEventHandler 
} from 'react'
import {
  getButtonComponent,
  getColor,
  getPathnameFromURL,
} from './Button.utils'
import { useTheme } from 'styled-components'
import { useRouter } from 'next/router'
import { GTMButtonClick } from '@/utils'
import { GA4ButtonClick } from '@/utils/GTM/GTMButtonClick'
import { useGlobalState } from '@/hooks'
import { IconWrapper } from './Button.styles'

const Button: React.ForwardRefRenderFunction<
  HTMLAnchorElement | HTMLButtonElement,
  NewButtonProps
> = (props, ref) => {

  const {
    buttonVariant = 'Filled', buttonColor = 'Primary', buttonIcon = null
  } = props
  const theme = useTheme()
  const {
    asPath, push, replace 
  } = useRouter()
  const { utmData } = useGlobalState()

  const mappedColor = getColor(theme, buttonVariant, buttonColor)
  const ButtonComponent = getButtonComponent(buttonVariant)

  const Icon = useMemo(() => {
    if(!buttonIcon) return null
    
    // if buttonIcon is an Element, return it
    if (typeof buttonIcon === 'function') {
      const IconComponent = buttonIcon as React.ComponentType
      return <IconComponent />
    }

    const { url = '', description } = buttonIcon || {}

    // TODO: add svg support for coloring icons
    return <IconWrapper>
      <Image
        src={url}
        alt={description || 'Button icon'}
        width={24}
        height={24}
        style={{
          maxWidth: '100%',
          height: 'auto',
          objectFit: 'contain',
          objectPosition: 'top left',
        }}
      />
    </IconWrapper>
  }, [buttonIcon])

  const customButtonOnClick = () => {
    if (props.onClick) props.onClick()
    if (props.eventLabel) GTMButtonClick(props.eventLabel)

    if (props.eventLabel === 'button_inbound_lead')
      GA4ButtonClick({
        buttonLabel: props.eventLabel,
        buttonText:
          typeof props.children === 'string' ? props.children : undefined,
        buttonDestination: props.href,
      })
  }

  if (props.href) {
    const currentPathname: string = asPath.split('?').shift() || ''
    const buttonLinkPathname: string = getPathnameFromURL(props.href || '')
    const arePathnamesTheSame = currentPathname === buttonLinkPathname

    const handleButtonClick: MouseEventHandler = (e) => {
      if (!arePathnamesTheSame || props.openInNewTab) {
        customButtonOnClick()
        return
      }

      e.preventDefault()

      const hash = props.href?.includes('#') && props.href.split('#').pop()

      if (!hash) {
        push(props.href || '')
      } else {
        push(
          props.href?.split('#').shift() || '',
          props.href?.split('#').shift() || '',
          {
            scroll: false,
          }
        ).then(() => {
          replace(props.href || '')
        })
      }
      customButtonOnClick()
    }

    const customHref = useMemo(() => {
      if (!props.href) return ''

      const isHrefToForm = props.href?.slice(props.href.length - 5) === '#form'
      const hasUtmData = utmData && Object.keys(utmData).length > 0
      const shouldModifyHref = isHrefToForm && hasUtmData

      if (!shouldModifyHref) return props.href || ''

      const hasQuery = props.href.includes('?')
      const hrefWithNoHashForm = props.href.slice(0, -5)
      const utmDataToString =
        utmData &&
        Object.keys(utmData)
          .map((key) => `${key}=${utmData[key]}`)
          .join('&')

      if (hasQuery) {
        return `${hrefWithNoHashForm}&${utmDataToString}#form`
      }

      return `${hrefWithNoHashForm}?${utmDataToString}#form`
      
    }, [props.href, utmData])

    const renderButtonLink = () => (
      <ButtonComponent
        as="a"
        onClick={handleButtonClick}
        mappedColor={mappedColor as any}
        href={arePathnamesTheSame ? customHref : undefined}
        ref={ref as any}
        target={props.openInNewTab ? '_blank' : undefined}
        {...props as any}
      >
        {props.children}
        {Icon}
      </ButtonComponent>
    )

    if (arePathnamesTheSame) {
      return renderButtonLink()
    }

    return (
      <Link href={customHref} passHref
        legacyBehavior>
        {renderButtonLink()}
      </Link>
    )
  }

  return (
    <ButtonComponent
      {...props}
      as="button"
      mappedColor={mappedColor as any}
      variant={buttonVariant}
      ref={ref}
    >
      {props.children}
      {Icon}
    </ButtonComponent>
  )
}

export default forwardRef(Button)
