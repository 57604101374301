import React from 'react'
import { AnimatedWord } from '../HeaderText/HeaderText.styles'

interface HighlightedSpanProps {
  text: string
}

const HighlightedSpan: React.FC<HighlightedSpanProps> = ({ text }) => {

  const hightlightedString = text.match(/\{\{([^)]+)\}\}/)?.[1]
  if (!hightlightedString) {
    return <>{text}</>
  }

  const highlightedString = (
    <AnimatedWord>{hightlightedString}</AnimatedWord>
  )

  const prefix = text.split('{{')[0]
  const suffix = text.split('}}')[1]

  return (
    <>
      {prefix}
      {highlightedString}
      {suffix}
    </>
  )
}

export default HighlightedSpan
