import { BannerProps } from '@/interfaces/banner'
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'
import { ISectionTheme } from '@/interfaces/theme'
import { useGetSectionTheme } from '@/hooks'
import { deepMerge } from '@/utils'

export const useAboveTheFoldTheme = (_theme?: SectionThemeKey, customTheme?: ISectionTheme, bannerProps?: BannerProps) => {
  // Get the base section theme that already includes:
  // 1. Base theme (light/dark/white)
  // 2. Page-level custom theme

  const bannerBackground = {
    ...(bannerProps?.backgroundImage && { backgroundImage: bannerProps.backgroundImage }),
    ...(bannerProps?.backgroundImage && { backgroundImageSize: 'cover' }),
  } as ISectionTheme
  
  const mergedSectionTheme = deepMerge(bannerBackground, customTheme)
  
  const theme = _theme === SectionThemeKey.White ? SectionThemeKey.Light : _theme
  const mergedTheme = useGetSectionTheme(theme || SectionThemeKey.Light, mergedSectionTheme)
  
  return {
    sectionTheme: mergedTheme,
  }
}
