import callContentful from './base.fetchers'
import { CategoryTypeKeys } from '@/interfaces/category'
import {
  IPmArticle,
  ArticleProps,
  PmArticleBooleanType,
} from '@/interfaces/article'

// Utils
import { thumbnailDataModify, relatedThumbnailDataModify } from '@/utils'

// Constants
import { NUM_OF_PM_ARTICLES } from '@/constants'
import { EventType } from '@/interfaces/eventsSection'

export const getBaseArticleQuery = (isPreviewMode?: boolean, locale?: string, limit = 3, order?: string, lang?: string) => {
  const langQuery = lang === undefined || lang === null
    ? ''
    : lang === ''
      ? 'where: { articleLang_exists: false }'
      : `where: { articleLang_contains: "${lang}" }`

  return `
    article: articleCollection(
      preview: ${isPreviewMode}
      locale: "${locale}"
      limit: ${limit}
      ${order ? `order: ${order}` : ''}
      ${langQuery}
    ) {
      items {
        sys {
          id
        }
        title
        articleSlug
        articleLang
        date
        topics: topicsCollection(
          preview: ${isPreviewMode},
          limit: 2,
          locale: "${locale}"
        ) {
          items {
            sys {
              id
            }
            title
            pageSlug
          }
        }
        heroImage {
          url
          description
        }
        thumbnailCopy
      }
    }
  `
}

// Get all thumbnails (limit to 4 for now)
type GetThumbnails = (args: {
  isPreviewMode: boolean
  locale: string
  limit?: number
  lang?: string
  fetcher?: (query: string, isPreviewMode: boolean) => Promise<any>
}) => Promise<ArticleProps[]>

const getThumbnails: GetThumbnails = async ({
  isPreviewMode,
  locale,
  limit = 3,
  lang,
  fetcher = callContentful,
}) => {
  const query = `{ ${getBaseArticleQuery(isPreviewMode, locale, limit, '[date_DESC]', lang)} }`

  const response = await fetcher(query, isPreviewMode)
  const data = response.data?.article?.items

  return thumbnailDataModify(data)
}

// Get all thumbnails belong to a topic
type GetRelatedArticleThumbnails = (args: {
  isPreviewMode: boolean
  locale: string
  type: CategoryTypeKeys
  topicSlug: string
  limit?: number
  fetcher?: (query: string, isPreviewMode: boolean) => Promise<any>
}) => Promise<any>

const getRelatedArticleThumbnails: GetRelatedArticleThumbnails = async ({
  isPreviewMode,
  locale,
  type,
  topicSlug,
  limit = 3,
  fetcher = callContentful,
}): Promise<any> => {
  const query = `
  {
    categoryCollection(
      preview: ${isPreviewMode},
      locale: "${locale}",
      where: { type: "${type}", pageSlug: "${topicSlug}" },
      limit: 1
    ) {
      items {
        title
        linkedFrom {
          ${getBaseArticleQuery(isPreviewMode, locale, limit)}
        }
      }
    }
  }
  `

  const response = await fetcher(query, isPreviewMode)
  const data = response?.data?.categoryCollection?.items?.[0]

  return relatedThumbnailDataModify(data)
}

type GetPmArticles = (args: {
  isPreviewMode: boolean
  locale: string
  pmArticleBooleanType?: PmArticleBooleanType
  limit?: number
}) => Promise<IPmArticle[]>

const getPmArticles: GetPmArticles = async ({
  isPreviewMode,
  locale,
  pmArticleBooleanType = PmArticleBooleanType.showOnPpInsights,
  limit,
}) => {
  const query = `
  {
    articleCollection(
      preview: ${isPreviewMode}
      where: { ${pmArticleBooleanType}: true },
      order: [date_DESC],
      locale: "${locale}",
      ${limit ? `limit: ${NUM_OF_PM_ARTICLES}` : ''}
    ) {
      items {
        sys {
          id
          publishedAt
        }
        title
        articleType
        slug
        date
        image {
          contentType
          url
          title
          description
        }
        seo {
          description
        }
      }
    }
  }
`

  const response = await callContentful(query, isPreviewMode, true)
  const data = response?.data?.articleCollection?.items?.filter(Boolean)
  const dataWithDescription = data?.map((article: IPmArticle) => {
    const {
      sys,
      seo,
      date,
    } = article
    return {
      ...article,
      description: seo?.description,
      startDate: sys?.publishedAt,
      type: EventType.PARCEL_MONITOR_REPORT,
      date: pmArticleBooleanType === PmArticleBooleanType.showOnPpInsights && date,
    }
  })
  return dataWithDescription
}

export {
  getThumbnails, getRelatedArticleThumbnails, getPmArticles 
}
