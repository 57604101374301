import styled from 'styled-components'

export const HeroImageImg = styled.img``

export const HeroImageImgWithAnimation = styled.img``

export const HeroImageWrapper = styled.div<{
  alignment?: 'Left' | 'Right' | 'Center'
  mobileOnly?: boolean
}>`
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  height: 275px;
  order: 1;

  ${HeroImageImg}, > .hero-image {
    display: block;
    border-radius: 15px;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      max-width: 100%;
      object-fit: cover;
    }
  }

  ${HeroImageImgWithAnimation} {
    opacity: 0;
  }

  ${HeroImageImgWithAnimation}, > .hero-image-with-animation {
    display: block;
    border-radius: 15px;
    transform: translateY(50px);

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      max-width: 100%;
      object-fit: cover;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex: 0 0 480px;
    width: 480px;
    height: 400px;
    margin-bottom: 0;

    ${(props) => props.alignment === 'Left' ? `
      margin-right: 68px;
      order: -1;
    ` : `
      margin-left: 68px;
      order: 1;
    `}

    ${(props) => props.mobileOnly && `
      display: none;
    `}

    img {
      height: 100%;
    }
  }
`
