import styled from 'styled-components'
import { defaultSectionTheme } from '../../../constants'
import { FilledButtonAsLink } from '../Button.styles'

export const ButtonLink = styled(FilledButtonAsLink)`
  background: transparent;
  border: 1px solid ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.secondaryButton.outlined?.borderColor
        : theme.sectionTheme
          ? theme.sectionTheme?.secondaryButton?.outlined?.borderColor
          : defaultSectionTheme.secondaryButton.outlined.borderColor};
  color: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.secondaryButton.outlined?.color
        : theme.sectionTheme
          ? theme.sectionTheme?.secondaryButton?.outlined?.color
          : defaultSectionTheme.secondaryButton.outlined.color};

  :not([disabled]):hover {
    background: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.surface02
      : buttonTheme
        ? buttonTheme.secondaryButton.outlined?.hoverBackgroundColor
        : theme.sectionTheme
          ? theme.sectionTheme?.secondaryButton?.outlined?.hoverBackgroundColor
          : defaultSectionTheme.secondaryButton.outlined.hoverBackgroundColor};

  & > span > svg,
  & > svg {
    &, & path {
      fill: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.secondaryButton.outlined?.color
        : theme.sectionTheme
          ? theme.sectionTheme?.secondaryButton?.outlined?.color
          : defaultSectionTheme.secondaryButton.outlined.color};
    }
  }

  :not([disabled]):hover {
    & > span > svg,
    & > svg {
      &, & path {
        fill: ${({ theme, buttonTheme }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : buttonTheme
        ? buttonTheme.secondaryButton.outlined?.color
        : theme.sectionTheme
          ? theme.sectionTheme?.secondaryButton?.outlined?.color
          : defaultSectionTheme.secondaryButton.outlined.color};
      }
  }
`

export const Button = styled(ButtonLink).attrs({
  as: 'button'
})``

export default { Button, ButtonLink }
