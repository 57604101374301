import React from 'react'
import { animated } from 'react-spring'

import { AssetRenderer, Container } from '@/components'
import { BannerProps } from '@/interfaces/banner'

import {
  HeroImageImg,
  HeroImageImgWithAnimation
} from '../../../../components/HeroImage'
import { useHeroImageSpring, useDataTransition } from '../../hooks'
import HeaderText from '../HeaderText'

import {
  ContentWrapper,
  LeftContainer,
  ProductName,
  SubCopy,
  ButtonsWrapper,
  HeaderWrapper,
} from '../../AboveTheFold.styles'
import { StyledButton, CustomDefaultHeroImageWrapper } from './Default.styles'
import { CustomAboveTheFoldWrapper } from '../Homepage/Homepage.styles'

const DefaultAboveTheFold: React.FC<BannerProps & {
  noPadding?: boolean
  noLocalTheme?: boolean
}> = ({
  productName,
  header,
  subCopy, // TODO: remove field after migration, this will be fallback for now
  subCopyLong,
  primaryButtonLink,
  primaryButtonOpenInNewTab,
  primaryButtonText,
  primaryButtonEventLabel,
  heroImages,
  secondaryButtonText,
  secondaryButtonLink,
  secondaryButtonEventLabel,
  secondaryButtonOpenInNewTab,
  alignment,
  contentAlignment,
  noPadding,
  contentWidth
}) => {

  const allHeroImages = heroImages?.filter(Boolean)
  const containsHeroImages = allHeroImages?.length
  const isUsingSingleHeroImage = allHeroImages?.length === 1
  const isUsingMultipleHeroImages = allHeroImages?.length > 1
  const hasButtons = (primaryButtonLink && primaryButtonText) || (secondaryButtonLink && secondaryButtonText)
  const isCenterAlign = alignment === 'Center'

  const {
    currentHeroImage,
    currentSubCopy,
    isAnimationStopped,
    isFirstItem,
  } = useDataTransition({ allHeroImages })
  const heroImageSpring = useHeroImageSpring(isAnimationStopped, isFirstItem)
  const isFullWidth = !containsHeroImages

  const renderProductNameAndHeaderText = (isDesktop = false) => (
    <HeaderWrapper isDesktop={isDesktop}>
      {productName && <ProductName centerOnMobile containsHeroImages={!!containsHeroImages}
        alignment={contentAlignment}>{productName}</ProductName>}
      <HeaderText
        centerOnMobile
        header={header}
        alignment={contentAlignment}
        heroImages={heroImages}
        currentSubCopy={currentSubCopy}
        isFirstItem={isFirstItem}
        noMobileBr
      />
    </HeaderWrapper>
  )

  return (
    <CustomAboveTheFoldWrapper noPadding={noPadding}>
      <Container style={{ position: 'relative' }}>
        {renderProductNameAndHeaderText()}
        <ContentWrapper
          isCenterAlign={isCenterAlign}
          isFullWidth={isFullWidth}
        >
          <LeftContainer centerOnMobile contentAlignment={contentAlignment}
            isFullWidth={contentWidth === 'Full'}>
            {renderProductNameAndHeaderText(true)}
            {(subCopyLong || subCopy) && (
              <SubCopy alignment={contentAlignment}>{subCopyLong || subCopy}</SubCopy>
            )}
            {hasButtons && (
              <ButtonsWrapper 
                buttonLength={
                  (primaryButtonLink && primaryButtonText ? 1 : 0) +
                  (secondaryButtonLink && secondaryButtonText ? 1 : 0)
                }
                alignment={contentAlignment}
              >
                {primaryButtonLink && primaryButtonText && (
                  <StyledButton
                    href={primaryButtonLink}
                    openInNewTab={primaryButtonOpenInNewTab}
                    eventLabel={primaryButtonEventLabel}
                  >
                    {primaryButtonText}
                  </StyledButton>
                )}
                {secondaryButtonLink && secondaryButtonText && (
                  <StyledButton
                    variant='secondary'
                    href={secondaryButtonLink}
                    openInNewTab={secondaryButtonOpenInNewTab}
                    eventLabel={secondaryButtonEventLabel}
                  >
                    {secondaryButtonText}
                  </StyledButton>
                )}
              </ButtonsWrapper>
            )}
          </LeftContainer>

          {/* Banner's hero images */}
          {allHeroImages?.length > 0 && 
            ((isUsingMultipleHeroImages && currentHeroImage?.url) ||
            (isUsingSingleHeroImage && allHeroImages[0]?.image?.url)) && (
            <CustomDefaultHeroImageWrapper alignment={alignment}>
              {isUsingSingleHeroImage && allHeroImages[0]?.image?.url && (
                <AssetRenderer
                  className='hero-image'
                  asset={allHeroImages[0]?.image}
                  ImageComponent={HeroImageImg}
                />
              )}
              {isUsingMultipleHeroImages && currentHeroImage?.url && (
                <AssetRenderer
                  className='hero-image-with-animation'
                  asset={currentHeroImage}
                  ImageComponent={HeroImageImgWithAnimation}
                  imageProps={{
                    as: animated.img,
                    style: heroImageSpring,
                  }}
                />
              )}
            </CustomDefaultHeroImageWrapper>
          )}
        </ContentWrapper>
      </Container>
    </CustomAboveTheFoldWrapper>
  )
}

export default DefaultAboveTheFold
