/**
 * Deep merge utility for theme objects
 * @param target - Target object to merge into
 * @param sources - Source objects to merge from, in order
 * @returns Deep merged object
 */
export const deepMerge = <T extends Record<string, any>>(target: T, ...sources: (Partial<T> | null | undefined)[]): T => {
  // Create a new object to avoid mutating the target
  let result = { ...target };
  
  // Process each source object in sequence
  for (const source of sources) {
    // If source is undefined or null, skip to the next source
    if (!source) continue;
    
    // Iterate through source properties
    Object.keys(source).forEach(key => {
      const sourceValue = source[key as keyof typeof source];
      const resultValue = result[key as keyof typeof result];
      
      // Skip undefined, null, or empty string source values
      if (sourceValue === undefined || sourceValue === null || sourceValue === "") return;
      
      // Handle object properties recursively (except for arrays)
      if (
        sourceValue !== null && 
        typeof sourceValue === 'object' && 
        resultValue !== null && 
        typeof resultValue === 'object' &&
        !Array.isArray(sourceValue) &&
        !Array.isArray(resultValue)
      ) {
        // Recursively merge objects
        result[key as keyof typeof result] = deepMerge(
          resultValue as Record<string, any>,
          sourceValue as Record<string, any>
        ) as any;
      } else {
        // For non-objects, directly assign the source value
        result[key as keyof typeof result] = sourceValue as any;
      }
    });
  }
  
  return result;
};
