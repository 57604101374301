import styled, { css } from 'styled-components'
import { theme } from '@/constants'
import { IconAlignment } from '@/interfaces/button.interfaces'

const smallButtonHeight = 34
const normalButtonHeight = 56 // TODO: recheck button height + padding (15px) for consistency

const HORIZONTAL_PADDING = 16
const BORDER_WIDTH = 1
const MIN_WIDTH = 96 + HORIZONTAL_PADDING * 2 + BORDER_WIDTH * 2

interface ButtonStyleProps {
  variant?: 'Filled' | 'Outlined' | 'Text'
  size?: 'small' | 'normal'
  disabled?: boolean
  buttonIcon?: React.ReactNode
  buttonIconPosition?: IconAlignment
}

const BaseDisabledStyles = css`
  opacity: 0.5 !important;
  cursor: not-allowed !important;
`

export const BaseButton = styled.a.attrs<ButtonStyleProps>((props) => ({
  style: {
    height: props.size === 'small' ? smallButtonHeight : normalButtonHeight,
    borderRadius: 8,
  }
}))<ButtonStyleProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0 15px;
  min-width: ${MIN_WIDTH}px;

  font-style: normal;
  font-size: ${theme.fontSizes[16]};
  line-height: ${theme.lineHeight[24]};
  font-weight: ${theme.fontWeight.bold};
  text-align: left;

  cursor: pointer;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;

  transition: .4s;

  ${props => props.buttonIcon && css`
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
  `}

  ${props => props.buttonIconPosition === IconAlignment.Left && `
    grid-auto-flow: dense;
    direction: rtl;
  `}

  & > svg {
    transition: .4s;

    & path {
      transition: .4s;
    }
  }

  ${props => props.disabled && css`
    ${BaseDisabledStyles}
  `}

  &[disabled] {
    ${BaseDisabledStyles}
  }
`

export const IconWrapper = styled.div`
  height: 20px;
  width: 20px;
`
