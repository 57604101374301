import Button from '@/components/Button'
import styled from 'styled-components'
import { CustomHeroImageWrapper } from '../../AboveTheFold.styles'

export const StyledButton = styled(Button)`
  padding: 0 16px;
`

export const CustomDefaultHeroImageWrapper = styled(CustomHeroImageWrapper)`
  ${({ theme }) => theme.mediaBreakpointDown.sm} {
    order: -1;
  }
`
