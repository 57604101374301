import { UITemplatesWrapper } from '@/constants'
import { WrapperContentInterface } from '@/interfaces/wrapperContent'
import MultipleIconAndCopy from './Templates/MultipleIconAndCopy'
import { useGetSectionTheme } from '@/hooks'
import LocalThemeProvider from '../LocalThemeProvider'

const WrapperContentItems: React.FC<WrapperContentInterface> = (props) => {
  const {
    uiTemplate
  } = props
  const defaultWrapperContentItems = MultipleIconAndCopy
  const UITemplateWrapper = uiTemplate ? (UITemplatesWrapper[uiTemplate] || defaultWrapperContentItems) : defaultWrapperContentItems

  const sectionTheme = useGetSectionTheme(props.theme, props.customTheme)

  return (
    <LocalThemeProvider theme={{ sectionTheme }}>
      <UITemplateWrapper {...props} />
    </LocalThemeProvider>
  )
}

export default WrapperContentItems
