import styled from 'styled-components'
import { BaseButton } from '../Button.styles'
import type { OutlinedButtonTheme } from '@/interfaces/theme'

export const OutlinedButton = styled(BaseButton)<{ mappedColor: OutlinedButtonTheme }>`
  background: transparent;
  border: 1px solid ${({ mappedColor }) => mappedColor?.borderColor};
  color: ${({ mappedColor }) => mappedColor?.color};

  & > span > svg,
  & > svg {
    &, & path {
      fill: ${({ mappedColor }) => mappedColor?.color};
    }
  }

  :not([disabled]):hover {
    background: ${({ mappedColor }) => mappedColor?.hoverBackgroundColor};
  }
`
