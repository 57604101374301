import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { defaultSectionTheme } from '@/constants'
import type { ISectionTheme } from '@/interfaces/theme'

const useSectionTheme = (): ISectionTheme => {
  const theme = useContext(ThemeContext)
  return theme.sectionTheme || defaultSectionTheme
}

export default useSectionTheme
