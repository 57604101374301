import styled from 'styled-components'
import { defaultSectionTheme, TextStyles } from '@/constants'
import { styledText } from '../Typography/Typography.styles'

export const SubTitleWrapper = styled.div`
  ${styledText[TextStyles['Heading 3']]}
  color: ${({ theme }) => theme.sectionTheme?.subText || defaultSectionTheme.subText};
  text-align: center;
  margin-top: 10px;
  margin-bottom: 50px;
  display: inline-block;

  & > p:first-child {
    margin-top: 0;
  }

  & > p:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 2']]}
    margin-top: 0px;
    margin-bottom: 80px;
  }
`
