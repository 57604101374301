import { lightTheme, darkTheme, whiteTheme } from '../constants'
import { SectionThemeKey } from '../interfaces/sectionThemeKey'
import type { ISectionTheme } from '@/interfaces/theme'
import { createContext, useContext } from 'react'
import { deepMerge } from '@/utils'

// Create a context for custom themes
export interface FullPageThemeContextType {
  customLightTheme?: ISectionTheme
  customDarkTheme?: ISectionTheme
}

export const FullPageThemeContext = createContext<FullPageThemeContextType>({
  customLightTheme: undefined,
  customDarkTheme: undefined
})

// Hook to access custom themes
export const useFullPageTheme = () => useContext(FullPageThemeContext)

// TODO: Find a way to extend this to support further levels of custom themes, current only support Page -> Parent -> Child
/**
 * Custom hook that combines context access with theme selection
 * @param {SectionThemeKey} theme section theme key
 * @param {ISectionTheme} sectionCustomTheme optional section-specific theme override
 * @return {ISectionTheme} the selected theme with custom overrides if available
 */
export const useGetSectionTheme = (
  theme?: SectionThemeKey,
  sectionCustomTheme?: ISectionTheme,
  wrapperCustomTheme?: ISectionTheme
): ISectionTheme => {
  // Get page-level custom themes from context
  const { customLightTheme, customDarkTheme } = useFullPageTheme();
  
  // Determine the base theme based on the theme key
  // Get base theme and corresponding custom theme
  const baseTheme =
    theme === SectionThemeKey.Dark
      ? darkTheme
      : theme === SectionThemeKey.White
        ? whiteTheme // ONLY REMOVE FOR BANNER
        : lightTheme;
  const customTheme =
    theme === SectionThemeKey.Dark
      ? customDarkTheme
      : customLightTheme;

  // Merge themes in order of precedence
  return deepMerge(
    baseTheme as any,
    customTheme as any,
    wrapperCustomTheme as any,
    sectionCustomTheme as any
  ) as ISectionTheme;
}
