// Interfaces
import { WrapperInterface } from '@/interfaces/wrapper.interfaces'
import { ContentItemInterface, ContentItemUITemplateKeys } from '@/interfaces/contentItem'

// Hooks
import { useGetSectionTheme } from '@/hooks'

// Components
import LocalThemeProvider from '@/components/LocalThemeProvider'
import { Button, ContentItem } from '@/components'

// Styles
import { WrapperContentItemsContainer } from '../WrapperContentItems/components'
import {
  WrapperContainer,
  InfoContainer,
  StatContainer,
  Title,
} from './Wrapper.styles'

//* Note: Wrapper only takes StatisticsAndIcon for now
const Wrapper: React.FC<WrapperInterface> = (props) => {
  const {
    title,
    theme,
    customTheme,
    buttonText,
    buttonLink,
    buttonEventLabel,
    openInNewTab,
    itemList,
  } = props
  const sectionTheme = useGetSectionTheme(theme, customTheme)

  return (
    <LocalThemeProvider theme={{ sectionTheme }}>
      <WrapperContentItemsContainer>
        <WrapperContainer>
          <InfoContainer>
            <Title>{title}</Title>
            {buttonLink && buttonText && (
              <Button
                href={buttonLink}
                openInNewTab={openInNewTab}
                eventLabel={buttonEventLabel}
              >
                {buttonText}
              </Button>
            )}
          </InfoContainer>
          <StatContainer hasBelowThreeItems={itemList.length < 3}>
            {itemList?.map((item: ContentItemInterface) =>
              item?.uiTemplate === ContentItemUITemplateKeys.StatisticsAndIcon
                ? <ContentItem
                  data={item}
                  key={item.sys?.id}
                  wrapperTheme={theme}
                  wrapperCustomTheme={customTheme}
                />
                : null
            )}
          </StatContainer>
        </WrapperContainer>
      </WrapperContentItemsContainer>
    </LocalThemeProvider>
  )
}

export default Wrapper
