import type { CustomThemeConfig, ICustomTheme } from '@/interfaces/theme'

/**
 * Convert custom theme format from API to the format compatible with SectionTheme interface
 * @param customTheme - The custom theme from the API
 * @returns A compatible theme object that matches the SectionTheme interface
 */
export const convertCustomThemeToSectionTheme = (customTheme?: ICustomTheme | CustomThemeConfig) => {
  if (!customTheme) return undefined;
  
  // Handle both direct config or wrapped in ICustomTheme
  const config = 'config' in customTheme ? customTheme.config : customTheme;
  
  return config;
}

export const headerNavigationDataModify = (data: any) => {
  const returnValue: any[] = []

  data?.forEach((item: any, index: number) => {
    const newItem = {
      ...item,
      sections: item?.sections?.items?.filter(Boolean) || [],
    }

    newItem.sections.forEach((el: any, index: number) => {
      const newEl = {
        ...el,
        menuLinkItems: el?.menuLinkItems?.items,
      }
      newItem.sections[index] = newEl
    })
    returnValue[index] = newItem
  })

  return returnValue
}

export const footerDataModify = (data: any) => {
  const returnValue: any[] = []

  data?.forEach((item: any, index: number) => {
    const newItem = {
      ...item,
      copyrightItemList: item?.copyrightItemList?.items?.filter(Boolean) || [],
    }
    returnValue[index] = newItem
  })
  return returnValue
}

export const fullPageDataModify = (data: any) => {
  if (!data) return

  const newItem = {
    ...data,
    sectionList: data?.sectionList?.items?.filter(Boolean) || [],
    // Convert custom themes to compatible format at page level
    customLightTheme: convertCustomThemeToSectionTheme(data?.customLightTheme),
    customDarkTheme: convertCustomThemeToSectionTheme(data?.customDarkTheme),
  }

  newItem.sectionList.forEach((el: any, index: number) => {
    const newEl = { ...el }

    // Banner Handle
    if (el.heroImages) newEl.heroImages = el.heroImages.items

    // Logos Handle
    if (el.firstCarrierList) newEl.firstCarrierList = el.firstCarrierList.items
    if (el.secondCarrierList)
      newEl.secondCarrierList = el.secondCarrierList.items

    // WrapperContentItems Handle
    // Wrapper Handle
    if (el.itemList) {
      newEl.itemList = el.itemList.items

      newEl.itemList.forEach((child: any, index: number) => {
        const newChild = { ...child }

        // If is content item, convert custom theme to section theme
        if (child?.customTheme?.config) {
          newChild.customTheme = convertCustomThemeToSectionTheme(child.customTheme)
        }

        // With Banner as Child
        if (child?.heroImages) newChild.heroImages = child.heroImages.items
        newEl.itemList[index] = newChild
      })
    }

    // LeadsWrapper Handle
    if (el.productImages) newEl.productImages = el.productImages.items
    if (el.customerLogos) newEl.customerLogos = el.customerLogos.items

    // Form Handle
    if (el.leadsForm?.formFieldList)
      newEl.leadsForm.formFieldList = el.leadsForm.formFieldList.items

    // Process section-level custom theme
    // At section level, customTheme contains the theme configuration for that section
    if (el.customTheme) {
      // Convert the section's customTheme to proper format
      newEl.customTheme = convertCustomThemeToSectionTheme(el.customTheme);
    }

    newItem.sectionList[index] = newEl
  })

  return newItem
}

export const stickerBannerDataModify = (data: any) => {
  if (!data) return

  const newItem = {
    ...data,
    formFieldList: data?.formFieldList?.items?.filter(Boolean) || [],
  }

  return newItem
}

export const popUpDataModify = (data: any) => {
  if (!data) return

  data.forEach((el: any) => {
    if (el.form?.formFieldList) {
      el.form.formFieldList = el.form.formFieldList.items
    }
  })

  return data
}

export const pressDataModify = (data: any) => {
  if (!data) return

  data?.sort((a: any, b: any) => {
    const aToDate = new Date(a.date).getTime()
    const bToDate = new Date(b.date).getTime()

    return bToDate - aToDate
  })

  return data
}

export const articleDataModify = (data: any) => {
  if (!data) return

  const newItem = {
    ...data,
    topics: data?.topics?.items?.filter(Boolean) || [],
    tags: data?.tags?.items?.filter(Boolean) || [],
  }

  return newItem
}

export const glossaryDataModify = (data: any) => {
  if (!data) return

  const newItem = {
    ...data,
    topics: data?.topics?.items?.filter(Boolean) || [],
    tags: data?.tags?.items?.filter(Boolean) || [],
  }

  return newItem
}

export const categoryDataModify = (data: any) => {
  if (!data) return

  const newItem = {
    ...data,
    leadsWrapperSection: data?.leadsWrapperSection?.items?.filter(Boolean) || [],
  }

  newItem?.leadsWrapperSection.forEach((el: any, index: number) => {
    const newEl = { ...el }

    // LeadsWrapper Handle
    if (el.productImages) newEl.productImages = el.productImages.items
    if (el.customerLogos) newEl.customerLogos = el.customerLogos.items

    // Form Handle
    if (el.leadsForm?.formFieldList)
      newEl.leadsForm.formFieldList = el.leadsForm.formFieldList.items

    newItem.leadsWrapperSection[index] = newEl
  })

  return newItem
}

export const thumbnailDataModify = (data: any) => {
  const returnValue: any[] = []

  data?.forEach((item: any, index: number) => {
    const newItem = {
      ...item,
      topics: item?.topics?.items?.filter(Boolean) || [],
    }
    returnValue[index] = newItem
  })

  return returnValue
}

export const relatedThumbnailDataModify = (data: any) => {
  if (!data) return

  const newItem = [...data.linkedFrom.article.items]

  newItem.forEach((el: any, index: number) => {
    const newEl = {
      ...el,
      topics: el?.topics?.items?.filter(Boolean) || []
    }

    newItem[index] = newEl
  })

  return newItem
}
