import React from 'react'
import { animated } from 'react-spring'
import Image from 'next/image'

import { AssetRenderer, Container } from '@/components'
import { BannerProps } from '@/interfaces/banner'

import {
  HeroImageImg,
  HeroImageImgWithAnimation
} from '../../../../components/HeroImage'
import HeaderText from '../HeaderText'
import { useHeroImageSpring, useDataTransition } from '../../hooks'

import {
  ContentWrapper,
  LeftContainer,
  ProductName,
  SubCopy,
  ButtonsWrapper,
  CustomHeroImageWrapper,
} from '../../AboveTheFold.styles'
import {
  CustomAboveTheFoldWrapper,
  CustomPrimaryButton,
  CustomSecondaryButton,
  IconWrapper,
} from './Experience.styles'

const ExperienceAboveTheFold: React.FC<BannerProps & {
  noPadding?: boolean
  noLocalTheme?: boolean
}> = ({
  icon,
  productName,
  header,
  subCopy, // TODO: remove field after migration, this will be fallback for now
  subCopyLong,
  primaryButtonLink,
  primaryButtonOpenInNewTab,
  primaryButtonText,
  primaryButtonEventLabel,
  heroImages,
  secondaryButtonText,
  secondaryButtonLink,
  secondaryButtonEventLabel,
  secondaryButtonOpenInNewTab,
  alignment,
  noPadding,
  contentAlignment,
  contentWidth
}) => {
  const allHeroImages = heroImages?.filter(Boolean)
  const containsHeroImages = allHeroImages?.length
  const isUsingSingleHeroImage = allHeroImages?.length === 1
  const isUsingMultipleHeroImages = allHeroImages?.length > 1
  const hasButtons = (primaryButtonLink && primaryButtonText) || (secondaryButtonLink && secondaryButtonText)
  const isCenterAlign = alignment === 'Center'

  const {
    currentHeroImage,
    currentSubCopy,
    isAnimationStopped,
    isFirstItem,
  } = useDataTransition({ allHeroImages })
  const heroImageSpring = useHeroImageSpring(isAnimationStopped, isFirstItem)

  const isFullWidth = !containsHeroImages
  return (
    <CustomAboveTheFoldWrapper noPadding={noPadding}>
      <Container style={{ position: 'relative' }}>
        <ContentWrapper
          isCenterAlign={isCenterAlign}
          isFullWidth={isFullWidth}
        >
          <LeftContainer contentAlignment={contentAlignment} isFullWidth={contentWidth === 'Full'}>
            {icon && (
              <IconWrapper contentAlignment={contentAlignment}>
                <Image
                  src={icon.url}
                  alt={icon.description || 'Experience above the fold icon'}
                  width={68}
                  height={68}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    objectPosition: 'top left'
                  }}
                />
              </IconWrapper>
            )}
            {productName && (
              <ProductName containsHeroImages={!!containsHeroImages} alignment={contentAlignment}>{productName}</ProductName>
            )}
            <HeaderText
              header={header}
              alignment={contentAlignment}
              heroImages={heroImages}
              currentSubCopy={currentSubCopy}
              isFirstItem={isFirstItem}
              noMobileBr
            />
            {(subCopyLong || subCopy) && (
              <SubCopy alignment={contentAlignment}>{subCopyLong || subCopy}</SubCopy>
            )}
            {hasButtons && (
              <ButtonsWrapper 
                buttonLength={
                  (primaryButtonLink && primaryButtonText ? 1 : 0) +
                  (secondaryButtonLink && secondaryButtonText ? 1 : 0)
                }
                alignment={contentAlignment}
              >
                {primaryButtonLink && primaryButtonText && (
                  <CustomPrimaryButton
                    href={primaryButtonLink}
                    openInNewTab={primaryButtonOpenInNewTab}
                    eventLabel={primaryButtonEventLabel}
                  >
                    {primaryButtonText}
                  </CustomPrimaryButton>
                )}
                {secondaryButtonLink && secondaryButtonText && (
                  <CustomSecondaryButton
                    href={secondaryButtonLink}
                    openInNewTab={secondaryButtonOpenInNewTab}
                    eventLabel={secondaryButtonEventLabel}
                  >
                    {secondaryButtonText}
                  </CustomSecondaryButton>
                )}
              </ButtonsWrapper>
            )}
          </LeftContainer>
          {/* Banner's hero images */}
          {allHeroImages?.length > 0 && 
            ((isUsingMultipleHeroImages && currentHeroImage?.url) ||
            (isUsingSingleHeroImage && allHeroImages[0]?.image?.url)) && (
            <CustomHeroImageWrapper alignment={alignment}>
              {isUsingSingleHeroImage && allHeroImages[0]?.image?.url && (
                <AssetRenderer
                  className='hero-image'
                  asset={allHeroImages[0]?.image}
                  ImageComponent={HeroImageImg}
                />
              )}
              {isUsingMultipleHeroImages && currentHeroImage?.url && (
                <AssetRenderer
                  className='hero-image-with-animation'
                  asset={currentHeroImage}
                  ImageComponent={HeroImageImgWithAnimation}
                  imageProps={{
                    as: animated.img,
                    style: heroImageSpring,
                  }}
                />
              )}
            </CustomHeroImageWrapper>
          )}
        </ContentWrapper>
      </Container>
    </CustomAboveTheFoldWrapper>
  )
}

export default ExperienceAboveTheFold
