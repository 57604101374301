import { theme } from '.'

const colors = theme.colors

const lightTheme = {
  text: colors.darkBlue,
  subText: colors.darkGray,
  lightSubText: colors.lightGray,
  highlightedText: colors.orange,
  hoverText: colors.white,
  background: colors.surface01,
  contrastThemeBackground: colors.darkBlue,
  dividerColor: colors.gray,
  primaryButton: {
    filled: {
      color: colors.white,
      backgroundColor: colors.orange,
      hoverBackgroundColor: colors.darkOrange,
    },
    text: {
      color: colors.orange,
      hoverColor: colors.darkOrange,
    },
    outlined: {
      color: colors.orange,
      borderColor: colors.orange,
      hoverBackgroundColor: colors.white,
    }
  },
  secondaryButton: {
    outlined: {
      color: colors.orange,
      borderColor: colors.orange,
      hoverBackgroundColor: colors.darkBeige,
    },
    text: {
      color: colors.orange,
      hoverColor: colors.darkPurple,
    },
    filled: {
      color: colors.white,
      backgroundColor: colors.orange,
      hoverBackgroundColor: colors.darkBeige,
    }
  },
  tertiaryButton: {
    outlined: {
      color: colors.orange,
      borderColor: colors.orange,
      hoverBackgroundColor: colors.darkBeige,
    },
    text: {
      color: colors.orange,
      hoverColor: colors.darkPurple,
    },
    filled: {
      color: colors.white,
      backgroundColor: colors.orange,
      hoverBackgroundColor: colors.darkBeige,
    }
  },
  navButton: {
    color: colors.darkBlue,
    hoverColor: colors.orange,
    borderColor: colors.silverGray,
  },
}

const darkTheme = {
  text: colors.white,
  subText: colors.white,
  lightSubText: colors.white,
  highlightedText: colors.orange,
  hoverText: colors.orange,
  background: colors.darkBlue,
  contrastThemeBackground: colors.surface01,
  dividerColor: colors.gray,
  primaryButton: {
    filled: {
      color: colors.white,
      backgroundColor: colors.orange,
      hoverBackgroundColor: colors.darkOrange,
    },
    text: {
      color: colors.orange,
      hoverColor: colors.darkOrange,
    },
    outlined: {
      color: colors.orange,
      borderColor: colors.orange,
      hoverBackgroundColor: colors.white,
    }
  },
  secondaryButton: {
    outlined: {
      color: colors.white,
      borderColor: colors.white,
      hoverBackgroundColor: colors.darkPurple,
    },
    text: {
      color: colors.white,
      hoverColor: colors.orange,
    },
    filled: {
      color: colors.white,
      backgroundColor: colors.orange,
      hoverBackgroundColor: colors.darkPurple,
    }
  },
  navButton: {
    color: colors.white,
    hoverColor: colors.orange,
    borderColor: colors.silverGray,
  },
}

const whiteTheme = {
  ...lightTheme,
  background: colors.white,
};

const defaultSectionTheme = lightTheme

export {
  lightTheme,
  darkTheme,
  whiteTheme,
  defaultSectionTheme
}
