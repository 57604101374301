import styled from 'styled-components'
import { TextStyles, defaultSectionTheme } from '@/constants'
import Typography from '@/components/Typography'
import Button from '@/components/Button'

export const Image = styled.img`
  object-fit: contain;
  object-position: center center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    object-position: left center;
  }
`

export const AssetWrapper = styled.div`
  ${Image}, > .dotlottie-container {
    width: 50px;
    height: 50px;
    display: block;
    flex: 0 0 auto;
    margin: 0 auto;
    margin-bottom: 10px;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      margin: unset;
    }
  }
`

export const TitleHolder = styled.div<{ minHeight: number | undefined }>`
  margin-top: 12px;  
  margin-bottom: 12px;
  height: auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: ${props => props.minHeight ? `${props.minHeight}px` : 'auto'};
  }
`

// TODO-Design: Figma is left-align, current is center-align
export const Title = styled(Typography).attrs({
  type: 'p',
  variant: TextStyles['Heading 3'],
})`
  color: ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text};
  min-height: 1px;
  word-break: break-word;
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    text-align: unset;
  }
`

export const Description = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  flex: auto;
  text-align: center;
  color: ${({ theme }) => theme.sectionTheme?.subText || defaultSectionTheme.subText};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    text-align: unset;
  }
`

// TODO: This is temporary solution to avoid Button migration
export const CustomStyledButton = styled(Button)`
  color: ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text};
  
  svg, svg path {
    fill: ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text};
    }
    
  &:not([disabled]):hover {
    border-bottom-color: ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text} !important;
    color: ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text} !important;

    svg, svg path {
      fill: ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text} !important;
    }
  }
`

export const LinkContainer = styled.div`
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-right: unset;
    margin-left: unset;
  }
`

export const Container = styled.div<{ hasPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 345px;
  width: 100%;
  background-color: ${({ theme }) => theme.sectionTheme?.background || defaultSectionTheme.background};
  background-image: ${({ theme }) => `url(${theme.sectionTheme?.backgroundImage?.url})`};
  background-size: ${({ theme }) => theme.sectionTheme?.backgroundImageSize};
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 100%;

  ${({ hasPadding }) => hasPadding && `
    padding: 24px;
  `}

  ${({ theme }) => theme.mediaBreakpointUp.sm} {
    width: 315px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: unset;
  }
`
