import { SectionThemeKey } from './sectionThemeKey'
import { CustomerCardInterface } from './CustomerCard'
import { ContentItemInterface } from './contentItem'
import { IRichTextDocument } from './common'
import { ISectionTheme } from './theme'

export enum WrapperUITemplateKeys {
  MultipleIconAndCopySection = 'Multiple icon & copy section',
  MultipleImageAndCopySection = 'Multiple image & copy section',
  CustomerNavigationTab = 'Customer navigation tab',
  ProductFeaturesList = 'Product features list',
  Statistics = 'Multiple Statistics',
  SliderForCards = 'Slider for cards',
  ProductSuiteTabs = 'Product suite tabs',
  Grid = 'Grid',
  CareersJobs = 'Careers jobs',
  FAQs = 'FAQs',
}

type WrapperContentItemInterface = CustomerCardInterface | ContentItemInterface

export type WrapperContentInterface<ItemType = WrapperContentItemInterface> = {
  sys?: { id: string }
  uiTemplate?: WrapperUITemplateKeys
  primaryTitle?: string
  secondaryTitle?: string
  subTitle?: IRichTextDocument
  theme?: SectionThemeKey
  customTheme?: ISectionTheme
  withSeparator?: boolean
  itemList: ItemType[]
  buttonLink?: string
  buttonText?: string
  buttonEventLabel?: string
  openInNewTab?: boolean
}
