import { Asset } from '@/interfaces/common'
import { ISectionTheme } from './theme'
import { SectionThemeKey } from './sectionThemeKey'

export enum CarrierUITemplateKeys {
  Animated = 'Animated',
  Static = 'Static',
}

export interface CarrierProps {
  uiTemplate?: CarrierUITemplateKeys
  theme?: SectionThemeKey
  customTheme?: ISectionTheme
  grayScale?: boolean
  smallTitle?: string
  title?: string
  firstCarrierList?: Asset[]
  secondCarrierList?: Asset[]
  buttonText?: string
  buttonLink?: string
  buttonEventLabel?: string
  openInNewTab?: boolean
  withSeparator?: boolean
}
