import getConfig from 'next/config'

import { ILanguageFields } from '../interfaces/head'
import { DOMAIN_LOCALES } from './../interfaces/locales'
import { FormUITemplateKeys } from '@/interfaces/form'

// Utils
import {
  headerNavigationDataModify,
  footerDataModify,
  fullPageDataModify,
  stickerBannerDataModify,
  minifyQuery,
  getFormattedPageSlug,
} from '@/utils'

// Fragment
import {
  bannerFields,
  carriersFields,
  contentFeedFields,
  contentItemFields,
  customerCardFields,
  productUseCaseFields,
  wrapperContentItemsFields,
  leadsWrapperFields,
  carrierListingFields,
  eventsSectionFields, 
  seoFields,
  wrapperFields,
  customThemeFields,
} from './fragments'

const { serverRuntimeConfig } = getConfig()

// Contentful Base Fetcher
const callContentful = async (query: any, isPreviewMode: boolean) => {
  const ACCESS_TOKEN = isPreviewMode
    ? serverRuntimeConfig.contentPreviewApiAccessToken
    : serverRuntimeConfig.contentDeliveryApiAccessToken
  const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${serverRuntimeConfig.contentfulSpace}/environments/${serverRuntimeConfig.contentfulEnvironment}`
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  }

  const ERROR_MESSAGE = 'Could not fetch data from Contentful!'

  try {
    const data = await fetch(fetchUrl, fetchOptions).then((response) =>
      response.json()
    )

    if (data.errors) {
      console.error(data.errors)
    }

    return data
  } catch (error) {
    console.error(error)
    throw new Error(JSON.stringify({ message: ERROR_MESSAGE, error }));
  }
}

// Get Header Navigation Data
// TODO - Khanh - Replace Promise<any> below
const getHeaderItem = async (isPreviewMode: boolean, locale: string): Promise<any> => {
  const query = `
    {
      headerItem: headerItemCollection(preview: ${isPreviewMode},
        order: [position_ASC],
        where: { isBlogHeader_not: true },
        locale: "${locale}") {
        items {
          sys {
            id
          }
          title
          link
          openInNewTab
          sections: sectionsCollection(preview: ${isPreviewMode}, limit: 4) {
            items {
              ... on MenuContentSection {
                sys {
                  id
                }
                title
                type
                icon {
                  url
                  description
                }
                content
                actionButtonTitle
                actionButtonLink
                buttonEventLabel
                menuLinkItems: menuLinkItemsCollection(preview: ${isPreviewMode}, limit: 10) {
                  items {
                    ... on MenuItem {
                      sys { 
                        id
                      }
                      title
                      icon {
                        url
                        description
                      }
                      description
                      link
                      openInNewTab
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.headerItem?.items

  return headerNavigationDataModify(data)
}

// Get Blog Page Header Navigation Data
const getBlogHeaderItem = async (isPreviewMode: boolean, locale: string): Promise<any> => {
  const query = `
    {
      headerItem: headerItemCollection(
        preview: ${isPreviewMode},
        order: [position_ASC],
        where: { isBlogHeader: true }
        locale: "${locale}") 
        {
        items {
          sections: sectionsCollection(preview: ${isPreviewMode}, limit: 1) {
            items {
              ... on MenuContentSection {
                sys {
                  id
                }
                menuLinkItems: menuLinkItemsCollection(preview: ${isPreviewMode}, limit: 8) {
                  items {
                    ... on MenuItem {
                      sys { 
                        id
                      }
                      title
                      link
                      openInNewTab
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.headerItem?.items

  return headerNavigationDataModify(data)
}

// Get footer informations
const getFooter = async (isPreviewMode: boolean, locale: string): Promise<any> => {
  const query = `
    {
      footer: footerCollection(preview: ${isPreviewMode}, limit: 1, locale: "${locale}") {
        items {
          sys {
            id
          }
          title
          newsletterTitle
          emailPlaceholder
          checkboxText
          emailRequiredErrorMessage
          emailFormatErrorMessage
          checkboxRequiredErrorMessage
          zapierHookId
          eventLabel
          buttonText
          buttonEventLabel
          officesTitle
          officeLocations
          copyrightTitle
          copyrightItemList: copyrightItemListCollection(preview: ${isPreviewMode}, limit: 5) {
            items {
              ... on MenuItem {
                sys { 
                  id
                }
                title
                link
                openInNewTab
              }
            }
          }
        }
      }
    }
  `
  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.footer?.items

  return footerDataModify(data)
}

// Get Full Page Content
// TODO - Khanh - Replace Promise<any> below
const getFullPageContent = async (isPreviewMode: boolean, pageSlug: string, locale: string): Promise<any> => {
  const limit = 20;
  let skip = 0;

  const getQuery = () => {
    return  `
    {
      fullPage: fullPageCollection(
        preview: ${isPreviewMode},
        where: { pageSlug: "${pageSlug}" },
        limit: 1,
        locale: "${locale}"
      ) {
        items {
          sys {
            id
          }
          pageTitle
          theme
          customLightTheme {
            ...customThemeFields
          }
          customDarkTheme {
            ...customThemeFields
          }
          sectionList: sectionListCollection(preview: ${isPreviewMode}, limit: ${limit}, skip: ${skip}) {
            total
            items {
              sys {
                id
              }
              __typename
              ... on WrapperContentItems {
                ...wrapperContentItemsFields
              }
              ... on Banner {
                ...bannerFields
              }
              ... on Carriers {
                ...carriersFields
              }
              ... on LeadsWrapper {
                ...leadsWrapperFields
              }
              ... on CarrierListing {
                ...carrierListingFields
              }
              ... on ContentFeed {
                ...contentFeedFields
              }
              ... on EventsSection {
                ...eventsSectionFields
              }
              ... on Wrapper {
                ...wrapperFields
              }
            }
          }
          seo {
            ...seoFields
          }
          hideHeaderFooter
        }
      }
    }
    ${customThemeFields()}
    ${bannerFields(isPreviewMode)}
    ${contentItemFields()}
    ${customerCardFields()}
    ${productUseCaseFields()}
    ${wrapperContentItemsFields(isPreviewMode)}
    ${carriersFields(isPreviewMode)}
    ${leadsWrapperFields(isPreviewMode, 700)}
    ${carrierListingFields()}
    ${contentFeedFields()}
    ${eventsSectionFields()}
    ${seoFields()}
    ${wrapperFields(isPreviewMode)}
  `
  }

  const response = await callContentful(minifyQuery(getQuery()), isPreviewMode);
  const {total: totalContentItems, items: currentItems } = response.data?.fullPage?.items?.[0]?.sectionList || {};
  let totalItemsLength = currentItems?.length;

  while(totalItemsLength < totalContentItems ) {
    skip += limit;
    const newResponse = await callContentful(minifyQuery(getQuery()), isPreviewMode);
    const { sectionList } = newResponse.data?.fullPage?.items?.[0] || {};
    const { items: newItems = [] } = sectionList || {};
    response.data?.fullPage?.items[0]?.sectionList?.items?.push(...newItems);
    totalItemsLength += newItems.length;
  }

  const data = response.data?.fullPage?.items[0];

  return fullPageDataModify(data)
}

// Get Full Page slug of all locales
const getFullPageSlugs = async (
  isPreviewMode: boolean,
  pageSlug: string,
  currentDomain: string,
  pageSysId: string | undefined,
): Promise<ILanguageFields[] | null> => {
  if (!pageSysId) return null

  const allWebDomains = Object.keys(DOMAIN_LOCALES)
  const filteredWebDomains = allWebDomains.filter((domain) => domain !== currentDomain)

  const pageLocalesQueries = filteredWebDomains
    ?.map((domain) => {
      return `{
        fullPage(
          preview: ${isPreviewMode},
          id: "${pageSysId}",
          locale: "${DOMAIN_LOCALES[domain].code}"
        ) {
          pageSlug
        }
      }
      `
    })

  const pageLocalesRes = await Promise.all(
    pageLocalesQueries.map((pageLocalesQuery) => callContentful(pageLocalesQuery, isPreviewMode)),
  )

  const filteredPageLocaleRes = pageLocalesRes.reduce((validPageLocaleRes, currentValue, index) => {
    if (!currentValue.data?.fullPage) {
      return validPageLocaleRes
    }

    const domain = filteredWebDomains[index]

    validPageLocaleRes.push({
      ...currentValue.data?.fullPage,
      locale: DOMAIN_LOCALES[domain].code,
      domain: domain,
    })

    return validPageLocaleRes
  }, [])

  filteredPageLocaleRes.push({
    pageSlug: getFormattedPageSlug(pageSlug),
    domain: currentDomain,
    locale: DOMAIN_LOCALES[currentDomain].code,
  })

  return filteredPageLocaleRes
}

// Get Full Page Id and hideHeaderFooter
const getFullPageSmallContent = async (
  isPreviewMode: boolean,
  pageSlug: string,
  locale: string
): Promise<any> => {
  const query = `
    {
      fullPageCollection(
        preview: ${isPreviewMode},
        where: { pageSlug: "${pageSlug}" },
        limit: 1,
        locale: "${locale}"
      ) {
        items {
          sys {
            id
          }
          hideHeaderFooter
        }
      }
    }
  `

  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.fullPageCollection?.items[0]
  return data
}

const getStickySubscribeBanner = async (
  isPreviewMode: boolean,
  locale: string
): Promise<any> => {
  const query = `
    {
      formCollection(
        preview: ${isPreviewMode}
        where: { uiTemplate: "${FormUITemplateKeys.StickySubscribeBanner}" }
        limit: 1
        order: sys_firstPublishedAt_DESC
        locale: "${locale}"
      ) {
        items {
          uiTemplate
          title
          eventLabel
          buttonText
          buttonEventLabel
          linkedInAdsId
          zapierHookId
          thankYouTitle
          thankYouMessage {
            json
          }
          formFieldList: formFieldListCollection(preview: ${isPreviewMode}, limit: 1) {
            items {
              ... on FormField {
                name
                label {
                  json
                }
                placeholder
                isMandatory
                type
                isNonCompanyEmailDomainBlocked
                width
                mandatoryCheckErrorMessage
                emailDomainBlockedCheckErrorMessage
                customErrorMessage
              }
            }
          }
        }
      }
    }
  `

  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.formCollection?.items[0]

  return stickerBannerDataModify(data)
}

export {
  getHeaderItem,
  getFooter,
  getFullPageContent,
  getFullPageSlugs,
  getFullPageSmallContent,
  getStickySubscribeBanner,
  getBlogHeaderItem,
}
