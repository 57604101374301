import styled, { css } from 'styled-components'
import Container from '@/components/Container'
import { HeroImageWrapper } from '../../components/HeroImage'
import SectionWrapper from '@/components/SectionWrapper'
import { styledText } from '@/components/Typography/Typography.styles'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography/Typography'

const aboveTheFoldPaddingTop = 80
const globeCutPartSize = 70
const backgroundImageRight = (1440 - 1148) / 2 + globeCutPartSize

export const AboveTheFoldWrapper = styled(SectionWrapper)<{ noPadding?: boolean }>`
  background-color: ${({ theme }) =>theme.pageTheme?.light || theme.sectionTheme?.background} ;
  background-image: ${({ theme }) => `url(${theme.sectionTheme?.backgroundImage?.url})`};
  background-size: ${({ theme }) => theme.sectionTheme?.backgroundImageSize || 'cover'};
  background-repeat: no-repeat;
  background-position: center;
  
  overflow: hidden;
  position: relative;

  ${({ noPadding }) => noPadding && css`
    ${Container} {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  `}
`

export const BackgroundImageWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
    position: absolute;
    right: 0;
    top: -${aboveTheFoldPaddingTop}px;
    left: 0;
    height: 100%;

    & > * {
      position: absolute !important;
      right: -${backgroundImageRight}px;
    }
  }
`

export const ContentWrapper = styled.div<{
  isCenterAlign?: boolean
  isFullWidth?: boolean
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white};
  flex-wrap: wrap;

  ${({ isCenterAlign }) => isCenterAlign && css`
    text-align: center;
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 554px);
    grid-column-gap: 40px;

    ${({ isFullWidth }) => isFullWidth && css`
      display: block;
    `}
  }
`

export const ProductName = styled.h2<{ alignment?: string, containsHeroImages?: boolean, centerOnMobile?: boolean }>`
  ${styledText[TextStyles['Large Paragraph Text']]}

  text-align: left;
  ${({ centerOnMobile }) => centerOnMobile && css`
    text-align: center;
  `}

  margin-top: 0;
  margin-bottom: 5px;
  color: ${({
    theme, alignment, containsHeroImages 
  }) => 
    theme.pageTheme
      ? theme.colors.darkBlue
      : alignment === 'Center' && !containsHeroImages
        ? theme.colors.orange
        : theme.sectionTheme?.text
};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    font-size: ${({ theme }) => theme.fontSizes[21]};
    line-height: ${({ theme }) => theme.lineHeight[29]};
    margin-bottom: 20px;
    text-align: ${({ alignment }) => alignment?.toLowerCase() || 'left'};
  }

  ${({
    alignment, theme, containsHeroImages 
  }) => 
    !theme.pageTheme &&
    !containsHeroImages &&
    alignment === 'Center' && css`
      font-size: ${theme.fontSizes[16]} !important;
      line-height: 24px !important;
      margin-bottom: 8px !important;
      font-weight: ${theme.fontWeight.bold};
    `
}
`

export const HeaderWrapper = styled.div<{ isDesktop: boolean }>`
  display: ${({ isDesktop }) => isDesktop ? 'none' : 'block'};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: ${({ isDesktop }) => isDesktop ? 'block' : 'none'};
  }
`

export const SubCopy = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})<{ alignment?: string }>`
  text-align: left;
  color: ${({ theme }) => theme.pageTheme ? theme.colors.black01 : theme.sectionTheme?.subText};
  
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Subheading']]}
    text-align: ${({ alignment }) => alignment?.toLowerCase() || 'left'};
  }
`

export const ButtonsWrapper = styled.div<{ buttonLength: number , alignment?: 'Left' | 'Right' | 'Center'}>`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 45px;

  ${({ buttonLength }) => buttonLength > 1 && `
    > * {
      flex: 1;
    }
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    justify-content: flex-start;
    margin-top: 50px;

    > * {
      flex: unset;
    }

    ${({ alignment }) => alignment === 'Center' && css`
      justify-content: center;
    `}

    ${({ alignment }) => alignment === 'Right' && css`
      justify-content: flex-end;
    `}

    ${({ alignment }) => (!alignment || alignment === 'Left') && css`
      justify-content: flex-start;
    `}
  }
`

export const CustomHeroImageWrapper = styled(HeroImageWrapper)`
  display: flex;
  align-items: center;

  > img {
    height: unset;
    max-height: 100%;
    object-fit: cover;
  }

  > .dotlottie-container {
    width: 100%;
    height: 100%;
  }

  > .hero-image-with-animation {
    transform: unset;
  }
`

export const LeftContainer = styled.div<{ contentAlignment?: string, isFullWidth?: boolean, centerOnMobile?: boolean }>`
  ${({ isFullWidth }) => !isFullWidth && css`
    width: 554px;
  `}

  ${({ contentAlignment }) => contentAlignment === 'Center' && css`
    margin: auto;
  `}

  ${({ contentAlignment }) => contentAlignment === 'Right' && css`
    margin-left: auto;
  `}

  ${({ contentAlignment }) =>  (!contentAlignment || contentAlignment === 'Left') && css`
    margin-right: auto;
  `}

  ${({ centerOnMobile }) => centerOnMobile && css`
    ${({ theme }) => theme.mediaBreakpointDown.sm} {
      ${ProductName},
      ${SubCopy} {
        text-align: center !important;
      }

      ${ButtonsWrapper} {
        justify-content: center !important;
      }
    }
  `}
`
