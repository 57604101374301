import { forwardRef, RefObject } from 'react'
import { UITemplatesContentItem } from '@/constants'
import { ContentItemInterface } from '@/interfaces/contentItem'
import { IconAndCopy } from './Templates'
import { useGetSectionTheme } from '@/hooks'
import LocalThemeProvider from '../LocalThemeProvider'
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'
import { ISectionTheme } from '@/interfaces/theme'

export interface ContentItemUITemplateProps {
  forwardedRef?: RefObject<HTMLElement>
  height?: number
  customProps?: Record<string, any>
}

interface ContentItemProps {
  data: ContentItemInterface
  height?: number
  wrapperTheme?: SectionThemeKey
  wrapperCustomTheme?: ISectionTheme
  customProps?: Record<string, any>
}

const defaultContentItem = IconAndCopy

const ContentItem = forwardRef<HTMLElement, ContentItemProps>((props, forwardedRef) => {
  const {
    data, customProps, height, wrapperTheme, wrapperCustomTheme
  } = props
  const { uiTemplate } = props.data
  const sectionTheme = useGetSectionTheme(data.theme ?? wrapperTheme, data.customTheme, wrapperCustomTheme)

  const UITemplateContentItem = uiTemplate ? (UITemplatesContentItem[uiTemplate] || defaultContentItem) : defaultContentItem
  const ForwardedRefComponent = forwardRef((_, ref) => (
    <UITemplateContentItem
      {...data}
      height={height}
      {...customProps}
      forwardedRef={ref as RefObject<HTMLParagraphElement>}
    />
  ))

  if (height) {
    return (
      <LocalThemeProvider theme={{ sectionTheme }}>
        <ForwardedRefComponent
          ref={forwardedRef}
        />
      </LocalThemeProvider>
    )  
  }

  return (
    <LocalThemeProvider theme={{ sectionTheme }}>
      <UITemplateContentItem {...data} customProps={customProps} />
    </LocalThemeProvider>
  )
})

export default ContentItem
