import styled, { css } from 'styled-components'
import Button from '@/components/Button'
import COLOR_PALETTE from '@/constants/colorPalette.constants'
import { SECTION_WRAPPER_PADDING_DESKTOP } from '@/components/SectionWrapper/SectionWrapper.styles'
import {
  AboveTheFoldWrapper,
  ButtonsWrapper,
  LeftContainer,
} from '../../AboveTheFold.styles'
import { Header, AnimatedWord } from '../HeaderText/HeaderText.styles'
import Image from 'next/image'
import { HeroImageWrapper } from '@/components/Banner/components/HeroImage'

const {
  blue,
  teal,
  purple,
} = COLOR_PALETTE

const heroImageStyling = css`
  display: block;
  margin-top: 32px;
  margin-bottom: -32px;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: -${SECTION_WRAPPER_PADDING_DESKTOP}px;
    margin-bottom: -${SECTION_WRAPPER_PADDING_DESKTOP}px;
    position: absolute;
    right: 0;
    top: 0;
  }
`

export const CustomAboveTheFoldWrapper = styled(AboveTheFoldWrapper)`
  ${Header} {
    margin-bottom: 16px;

    ${AnimatedWord}, span[style^='color'] {
      background: linear-gradient(90deg, ${purple[3]} 20%, ${blue[6]} 50%, ${teal[3]} 80%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent !important;
    }
  }

  ${ButtonsWrapper} {
    margin-top: 24px;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      margin-top: 32px;
    }
  }

  .hero-image, .hero-image-with-animation {
    ${heroImageStyling}
  }

  ${LeftContainer} {
    z-index: 1;
  }
`

export const HomepagePrimaryButton = styled(Button)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  :not([disabled]):hover {
    background-color: ${({ theme }) => theme.colors.orangeHover} !important;
  }
`

export const HomepageSecondaryButton = styled(Button).attrs({
  variant: 'secondary',
})`
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  :not([disabled]):hover {
    border-color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.white05} !important;
  }
`

export const CustomHomepageHeroImageWrapper = styled(HeroImageWrapper)`
  height: unset;
  width: unset;
  margin-top: 0;
`

export const HeroImageContainer = styled.div<{ alignment?: string }>`
  position: relative;
  margin-top: 32px;
  margin-bottom: -32px;
  margin-left: auto;
  margin-right: auto;
  width: 327px;
  height: 237px;
  max-height: unset !important;

  > * {
    width: auto;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: -${SECTION_WRAPPER_PADDING_DESKTOP}px;
    margin-bottom: -${SECTION_WRAPPER_PADDING_DESKTOP}px;
    position: absolute;
    width: 612px;
    height: 444px;

    ${({ alignment }) => alignment === 'Left' ? css`
      left: 0;
      top: 0;
    ` : css`
      right: 0;
      top: 0;
    `}
  }
`

export const HomepageHeroImageImg = styled(Image)``

export const HomepageHeroImageImgWithAnimation = styled(HomepageHeroImageImg)`
  opacity: 0;
`

export const HomepageButtonsWrapper = styled(ButtonsWrapper)`
  display: flex;
  flex-direction: row;
  gap: 16px;

  > * {
    margin: 0 !important;
  }
`
