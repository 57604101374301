import { ContentItemInterface } from '@/interfaces/contentItem'

import ArrowRight from '../../../../public/images/icons/arrow-right.svg'
import { 
  Container,
  Image,
  TitleHolder,
  Title,
  Description,
  LinkContainer,
  AssetWrapper,
  CustomStyledButton,
} from './IconAndCopy.styles'
import { AssetRenderer } from '@/components'
import { ContentItemUITemplateProps } from '../../ContentItem'

const IconAndCopy: React.FC<ContentItemInterface & ContentItemUITemplateProps> = (props) => {
  const {
    title,
    description,
    image,
    buttonLink,
    buttonEventLabel,
    openInNewTab,
    forwardedRef,
    height,
    hasPadding = false,
  } = props
  //in case buttonText contains empty spaces
  const buttonText = props.buttonText?.trim() || ''
  
  return (
    <Container hasPadding={hasPadding}>
      {image && (
        <AssetWrapper>
          <AssetRenderer
            asset={image}
            ImageComponent={Image}
          />
        </AssetWrapper>
      )}
      {
        title &&
        <TitleHolder minHeight={height}>
          <Title ref={forwardedRef}>{title}</Title>
        </TitleHolder>
      }
      <Description>{description}</Description>
      {
        buttonLink && (
          <LinkContainer>
            <CustomStyledButton
              variant='link'
              href={buttonLink}
              eventLabel={buttonEventLabel}
              openInNewTab={openInNewTab}
              icon={ArrowRight}
            >
              {buttonText || 'Learn more'}
            </CustomStyledButton>
          </LinkContainer>
        )
      }
    </Container>
  )
}

export default IconAndCopy
