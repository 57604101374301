import styled, { css } from 'styled-components'
import Button from '@/components/Button'
import {
  AboveTheFoldWrapper,
  ButtonsWrapper,
  CustomHeroImageWrapper,
} from '../../AboveTheFold.styles'
import { 
  AnimatedWord,
  Header,
  HighlightedHeader, 
  HighlightedWord,
} from '../HeaderText/HeaderText.styles'

export const CustomAboveTheFoldWrapper = styled(AboveTheFoldWrapper)`
  ${Header} {
    margin-bottom: 16px;

    ${HighlightedHeader} {
      color: ${({ theme }) => theme.pageTheme?.main} !important;
    }

    ${AnimatedWord}, span[style^='color'] {
      color: ${({ theme }) => theme.pageTheme?.main} !important;
    }

    ${HighlightedWord} {
      padding-bottom: 49px;
    }
  }

  ${ButtonsWrapper} {
    margin-top: 24px;

    > * {
      flex: unset;
    }

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      margin-top: 32px;
    }
  }

  ${CustomHeroImageWrapper} {
    margin-bottom: unset;
    margin-left: unset;
    margin-top: 24px;
    height: 100%;
    width: 100%;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      margin-right: unset;
      margin-top: unset;
    }
  }
`

// NEW-BUTTON: Filled - Product Experience
export const CustomPrimaryButton = styled(Button)`
  padding: 15px;
  background-color: ${({ theme }) => theme.pageTheme ? theme.pageTheme.main : theme.colors.darkBlue};
  border-color: ${({ theme }) => theme.pageTheme ? theme.pageTheme.main : theme.colors.darkBlue};  

  :not([disabled]):hover {
    background-color: ${({ theme }) => theme.pageTheme ? theme.pageTheme.dark : theme.colors.ppBlueHover};
    border-color: ${({ theme }) => theme.pageTheme ? theme.pageTheme.dark : theme.colors.ppBlueHover};
  }
`

// NEW-BUTTON: Text - Product Experience
export const CustomSecondaryButton = styled(Button).attrs({
  variant: 'link'
})`
  color: ${({ theme }) => theme.pageTheme ? theme.pageTheme.main : theme.colors.darkBlue} !important;

  // Custom for only Text button
  padding: 4px 0 3px 0;
  border-radius: 0 !important;
  border-bottom: 2px solid transparent;

  :not([disabled]):hover {
    color: ${({ theme }) => theme.pageTheme ? theme.pageTheme.dark : theme.colors.ppBlueHover} !important;
    border-color: ${({ theme }) => theme.pageTheme ? theme.pageTheme.dark : theme.colors.ppBlueHover} !important;
  }
`

export const IconWrapper = styled.div<{ contentAlignment?: string }>`
  margin-bottom: 16px;
  height: 68px;
  width: 68px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${({ contentAlignment }) => contentAlignment === 'Center' && css`
      margin: auto;
      margin-bottom: 16px;
    `}
    
    ${({ contentAlignment }) => contentAlignment === 'Right' && css`
      margin-left: auto;
      margin-right: unset;
    `}
    
    ${({ contentAlignment }) => (!contentAlignment || contentAlignment === 'Left') && css`
      margin-right: auto;
      margin-left: unset;
    `} 
  }
`
