import { ThemeProvider } from 'styled-components'
import { deepMerge } from '@/utils'
import { ISectionTheme } from '@/interfaces/theme'

interface LocalThemeProviderProps {
  theme: {
    pageTheme?: any
    sectionTheme?: any
    customLightTheme?: any
    customDarkTheme?: any
    [key: string]: any
  }
  children: React.ReactNode
}

const LocalThemeProvider: React.FC<LocalThemeProviderProps> = ({ theme: localTheme, children }) => {
  const { ...themeProps } = localTheme

  return (
    <ThemeProvider theme={(oldTheme) => {
      const oldSectionTheme = oldTheme?.sectionTheme as Record<string, any>
      const newSectionTheme = themeProps?.sectionTheme as Record<string, any>
      const mergedSectionTheme = oldSectionTheme && newSectionTheme ? deepMerge(oldSectionTheme, newSectionTheme) : newSectionTheme

      return {
        ...oldTheme,
        ...themeProps,
        sectionTheme: mergedSectionTheme as ISectionTheme
      }
    }}>
      {children}
    </ThemeProvider>
  )
}

export default LocalThemeProvider
