import {
  useRef,
  useState,
} from 'react'

import ArrowRight from '../../public/images/icons/arrow-right.svg'
import type { ProductUseCaseProps } from '@/interfaces/productUseCase'
import { useHighlightString, useIsomorphicLayoutEffect } from '@/hooks'
import { AssetRenderer, NewButton } from '@/components'

import {
  Wrapper,
  ItemWrapper,
  Title,
  ProductMockup,
  VerticalConnectorLine,
  ProductMockupWrapper,
  SubPoints,
  SubPoint,
  SubPointTitle,
  SubPointDesc,
  SubTitle,
  SubCopy,
  StyledIcon,
} from './ProductUseCase.styles'

// TODO: remove field subCopy after migration, this will be fallback for now
const ProductUseCase: React.FC<ProductUseCaseProps> = (data) => {
  const imageRef = useRef<HTMLImageElement>(null)
  const [height, setHeight] = useState(0)

  const handleImageLoad = () => {
    const height = imageRef?.current?.clientHeight
    setHeight(height || 0)
  }

  useIsomorphicLayoutEffect(() => {
    handleImageLoad()
  })

  const { button } = data
  const {
    buttonVariant, buttonColor, buttonIcon, buttonIconPosition 
  } = button || {}

  return (
    <Wrapper buttonCopy={data.buttonCopy}>
      <ItemWrapper alignment={data.alignment} isStandalone={data.isStandalone}>
        {data.title && <Title>{useHighlightString(data.title)}</Title>}
      </ItemWrapper>
      <ItemWrapper>
        <ProductMockupWrapper alignment={data.alignment}>
          <AssetRenderer
            asset={data.productMockup}
            ImageComponent={ProductMockup}
            imageProps={{
              ref: imageRef,
              onLoad: handleImageLoad,
            }}
            videoProps={{
              style: { backgroundColor: 'unset' },
            }}
          />
          {data.showVerticalConnectorLine && <VerticalConnectorLine imageHeight={height} alignment={data.alignment} />}
        </ProductMockupWrapper>
        <SubPoints alignment={data.alignment} subTitle={data.subTitle} >
          {data.icon?.url && (
            <StyledIcon src={data.icon?.url} alt={data.icon?.description} />
          )}
          {data.subTitle && <SubTitle>{data.subTitle}</SubTitle>}
          {(data.subCopy || data.subCopyLong) && <SubCopy>{data.subCopyLong || data.subCopy}</SubCopy>}
          {data.subPoints?.items
            ?.filter((item: any) => item.title || item.desc)
            ?.map((item, index) => {
              return (
                <SubPoint 
                  key={index} 
                  isStandalone={data.isStandalone} 
                  subTitle={data.subTitle} 
                >
                  {item.title && <SubPointTitle isStandalone={data.isStandalone}>{item.title}</SubPointTitle>}
                  <SubPointDesc isStandalone={data.isStandalone}>{item.desc}</SubPointDesc>
                </SubPoint>)
            })}
          {data.buttonCopy && (
            <NewButton
              buttonVariant={buttonVariant || 'Text'}
              buttonColor={buttonColor || 'Secondary'}
              buttonIcon={buttonIcon || ArrowRight}
              buttonIconPosition={buttonIconPosition}
              href={data.buttonLink}
              openInNewTab={data.openInNewTab}
            >
              {data.buttonCopy}
            </NewButton>
          )}
        </SubPoints>
      </ItemWrapper>
    </Wrapper>
  )
}

export default ProductUseCase
