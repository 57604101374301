import { SectionThemeKey } from './sectionThemeKey'
import { IRichTextDocument } from './common'
import { IFormProps } from './form'
import { ISectionTheme } from './theme'

export enum LeadsWrapperUITemplateKeys {
  LeadsPage = 'Leads page',
  LeadGenBanner = 'Lead gen banner',
  RichTextParagraph = 'Rich-text paragraph',
}

export interface LeadsWrapperProps {
  sys?: { id: string }
  uiTemplate?: LeadsWrapperUITemplateKeys
  title?: string
  leadSubCopy?: IRichTextDocument
  leadsForm?: IFormProps
  backgroundImage?: {
    url: string
    description: string
  }
  buttonText?: string
  buttonLink?: string
  buttonEventLabel?: string
  openInNewTab?: boolean
  highlightedSubCopy?: string
  productImages?: {
    sys?: { id: string }
    url: string
    description: string
  }[]
  customerLogos?: {
    sys?: { id: string }
    url: string
    description: string
  }[]
  tinyCopy?: string
  alignment?: 'Left' | 'Center'
  theme?: SectionThemeKey
  customTheme?: ISectionTheme
}
