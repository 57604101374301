import styled, { css } from 'styled-components'
import { TextStyles } from '@/constants'
import { SectionTheme } from '../../styled'
import { styledText } from '../Typography/Typography.styles'
import { IconAlignment } from '@/interfaces/button.interfaces'

const smallButtonHeight = 34
const normalButtonHeight = 56 // TODO: recheck button height + padding (15px) for consistency
// const minWidth = 96 // 128 - (15 [padding] + 1 [border]) * 2 = 96
const HORIZONTAL_PADDING = 16
const BORDER_WIDTH = 1
const MIN_WIDTH = 96 + HORIZONTAL_PADDING * 2 + BORDER_WIDTH * 2

const BaseDisabledStyles = css`
  opacity: 0.5 !important;
  cursor: not-allowed !important;
`

interface ButtonStyleProps {
  variant?: 'primary' | 'secondary' | 'link' | 'icon'
  size?: 'small' | 'normal'
  disabled?: boolean
  icon?: any
  buttonTheme?: SectionTheme
  iconAlignment?: IconAlignment
}

export const FilledButtonAsLink = styled.a.attrs<ButtonStyleProps>((props) => ({
  style: {
    height: props.size === 'small' ? smallButtonHeight : normalButtonHeight,
    borderRadius: 8,
  }
}))<ButtonStyleProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0;
  min-width: ${MIN_WIDTH}px;

  ${styledText[TextStyles['Button']]}
  cursor: pointer;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;

  transition: .4s;

  ${props => props.icon && css`
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
  `}

  ${props => props.iconAlignment === IconAlignment.Left && `
    grid-auto-flow: dense;
    direction: rtl;
  `}

  & > svg {
    transition: .4s;

    & path {
      transition: .4s;
    }
  }

  ${props => props.disabled && css`
    ${BaseDisabledStyles}
  `}

  &[disabled] {
    ${BaseDisabledStyles}
  }
`
