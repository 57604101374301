import type { Asset } from '@/interfaces/common'
import type { SectionThemeKey } from './sectionThemeKey'
import type { PageThemeKey } from './pageTheme.interfaces'
import type { ISectionTheme } from '@/interfaces/theme'

export enum BannerUITemplateKeys {
  AboveTheFold = 'Above the fold',
  ProductSuiteContent = 'Product suite content',
}

export interface BannerProps {
  __typename: string
  uiTemplate?: BannerUITemplateKeys
  icon: Asset
  productName?: string
  header?: string
  productHeader?: {
    title?: string
    description?: string
    image?: Asset
  }
  subCopy?: string
  subCopyLong?: string
  backgroundImage?: Asset
  heroImages: {
    title?: string
    image?: {
      url: string
      description?: string
    }
  }[]
  primaryButtonText?: string
  primaryButtonLink?: string
  primaryButtonEventLabel?: string
  primaryButtonOpenInNewTab?: boolean
  secondaryButtonText?: string
  secondaryButtonLink?: string
  secondaryButtonEventLabel?: string
  secondaryButtonOpenInNewTab?: boolean
  alignment?: 'Left' | 'Right' | 'Center',
  contentAlignment?: 'Left' | 'Right' | 'Center',
  theme?: SectionThemeKey
  customTheme?: ISectionTheme
  backgroundColor?: PageThemeKey
  referenceContent?: {
    ref: string
    data: any[]
  }
  contentWidth?: 'Full' | 'Half'
}

// TODO: For Banner type definiton late
