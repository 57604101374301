import React from 'react'
import { animated } from 'react-spring'

import { AssetRenderer } from '@/components'
import { BannerProps } from '@/interfaces/banner'

import HeaderText from '../HeaderText'
import { useHeroImageSpring, useDataTransition } from '../../hooks'

import {
  ContentWrapper,
  LeftContainer,
  ProductName,
  SubCopy,
  CustomHeroImageWrapper,
} from '../../AboveTheFold.styles'
import {
  CustomAboveTheFoldWrapper,
  CustomHomepageHeroImageWrapper,
  HeroImageContainer,
  HomepageButtonsWrapper,
  HomepageHeroImageImg,
  HomepageHeroImageImgWithAnimation,
  HomepagePrimaryButton,
  HomepageSecondaryButton,
} from './Homepage.styles'

const HomepageAboveTheFold: React.FC<BannerProps & {
  noPadding?: boolean
  noLocalTheme?: boolean
}> = ({
  productName,
  header,
  subCopy, // TODO: remove field after migration, this will be fallback for now
  subCopyLong,
  primaryButtonLink,
  primaryButtonOpenInNewTab,
  primaryButtonText,
  primaryButtonEventLabel,
  heroImages,
  secondaryButtonText,
  secondaryButtonLink,
  secondaryButtonEventLabel,
  secondaryButtonOpenInNewTab,
  alignment,
  contentAlignment,
  contentWidth
}) => {
  const allHeroImages = heroImages?.filter(Boolean)
  const containsHeroImages = allHeroImages?.length
  const isUsingSingleHeroImage = allHeroImages?.length === 1
  const isUsingMultipleHeroImages = allHeroImages?.length > 1
  const hasButtons = (primaryButtonLink && primaryButtonText) || (secondaryButtonLink && secondaryButtonText)
  const isCenterAlign = alignment === 'Center'

  const {
    currentHeroImage, currentSubCopy, isAnimationStopped, isFirstItem 
  } = useDataTransition({ allHeroImages })
  const heroImageSpring = useHeroImageSpring(isAnimationStopped, isFirstItem)

  const isShowHeroImage =
    allHeroImages?.length > 0 &&
    ((isUsingMultipleHeroImages && currentHeroImage?.url) ||
      (isUsingSingleHeroImage && allHeroImages[0]?.image?.url))

  const isFullWidth = !containsHeroImages
      
  return (
    <CustomAboveTheFoldWrapper>
      <ContentWrapper
        isCenterAlign={isCenterAlign}
        isFullWidth={isFullWidth}
      >
        <LeftContainer contentAlignment={contentAlignment} isFullWidth={contentWidth === 'Full'}>
          {productName && (
            <ProductName containsHeroImages={!!containsHeroImages} alignment={contentAlignment}>
              {productName}
            </ProductName>
          )}
          <HeaderText
            header={header}
            alignment={contentAlignment}
            heroImages={heroImages}
            currentSubCopy={currentSubCopy}
            isFirstItem={isFirstItem}
            noMobileBr
          />
          {(subCopyLong || subCopy) && (
            <SubCopy alignment={contentAlignment}>{subCopyLong || subCopy}</SubCopy>
          )}
          {hasButtons && (
            <HomepageButtonsWrapper
              buttonLength={
                (primaryButtonLink && primaryButtonText ? 1 : 0) +
                (secondaryButtonLink && secondaryButtonText ? 1 : 0)
              }
              alignment={contentAlignment}
            >
              {primaryButtonLink && primaryButtonText && (
                <HomepagePrimaryButton
                  href={primaryButtonLink}
                  openInNewTab={primaryButtonOpenInNewTab}
                  eventLabel={primaryButtonEventLabel}
                >
                  {primaryButtonText}
                </HomepagePrimaryButton>
              )}
              {secondaryButtonLink && secondaryButtonText && (
                <HomepageSecondaryButton
                  href={secondaryButtonLink}
                  openInNewTab={secondaryButtonOpenInNewTab}
                  eventLabel={secondaryButtonEventLabel}
                >
                  {secondaryButtonText}
                </HomepageSecondaryButton>
              )}
            </HomepageButtonsWrapper>
          )}
        </LeftContainer>

        {/* Banner's hero images */}
        {isShowHeroImage && (
          <>
            {isUsingSingleHeroImage && allHeroImages[0]?.image?.url && (
              <CustomHomepageHeroImageWrapper alignment={alignment}>
                <HeroImageContainer alignment={alignment}>
                  <AssetRenderer
                    className="hero-image"
                    asset={allHeroImages[0]?.image}
                    ImageComponent={HomepageHeroImageImg}
                    imageProps={{
                      priority: true,
                      quality: 100,
                      fill: true,
                    }}
                  />
                </HeroImageContainer>
              </CustomHomepageHeroImageWrapper>
            )}
            {isUsingMultipleHeroImages && currentHeroImage?.url && (
              <CustomHeroImageWrapper alignment={alignment}>
                <AssetRenderer
                  className="hero-image-with-animation"
                  asset={currentHeroImage}
                  ImageComponent={HomepageHeroImageImgWithAnimation}
                  imageProps={{
                    as: animated.img,
                    style: heroImageSpring,
                  }}
                />
              </CustomHeroImageWrapper>
            )}
          </>
        )}
      </ContentWrapper>
    </CustomAboveTheFoldWrapper>
  )
}

export default HomepageAboveTheFold
