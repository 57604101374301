import React, { useContext } from 'react'
import { SectionThemeKey } from '../../../interfaces/sectionThemeKey'
import { AccordionContext, PanelKey } from '../Accordion'
import {
  PanelArrow,
  PanelTitle, 
  PanelContent, 
  PanelWrapper, 
  LinkPanelTitle,
  PanelContentWrapper
} from './Panel.styles'
import { useGetSectionTheme } from '@/hooks'

export interface IPanelProps {
    title: React.ReactNode
    panelKey: PanelKey
    panelTheme?: SectionThemeKey
    link?: string
    openInNewTab?: boolean
}

const Panel: React.FC<IPanelProps> = ({
  link,
  title, 
  panelKey, 
  panelTheme, 
  openInNewTab,
  children
}) => {
  const { selectedPanel, setSelectedPanel } = useContext(AccordionContext)
  const sectionTheme = useGetSectionTheme(panelTheme)

  const handleClick = () => {
    setSelectedPanel(selectedPanel === panelKey ? null : panelKey)
  }

  return (
    <PanelWrapper 
      panelTheme={sectionTheme}
      onClick={handleClick}
      isOpen={selectedPanel === panelKey}
    >
      {link ? (
        <LinkPanelTitle href={link} target={openInNewTab ? '_blank' : '_self'}>
          <PanelTitle>{title}</PanelTitle>
        </LinkPanelTitle>
      ) : (
        <PanelTitle>
          {title}
          <PanelArrow />
        </PanelTitle>
      )}
      <PanelContentWrapper isOpen={selectedPanel === panelKey}>
        {!link && <PanelContent>{children}</PanelContent>}
      </PanelContentWrapper>
    </PanelWrapper>
  )
}

export default Panel
