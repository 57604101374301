import styled from 'styled-components'
import { defaultSectionTheme } from '../../../constants'
import { FilledButtonAsLink } from '../Button.styles'

const buttonSize = 37

export const ButtonLink = styled(FilledButtonAsLink)`
  padding: 0;
  min-width: unset;
  height: ${buttonSize}px !important;
  width: ${buttonSize}px;
  border: none;
  border-radius: 50% !important;
  background: ${({ theme, buttonTheme }) => 
    buttonTheme
      ? buttonTheme.primaryButton.filled.backgroundColor
      : theme.sectionTheme
        ? theme.sectionTheme?.primaryButton?.filled?.backgroundColor
        : defaultSectionTheme.primaryButton?.filled?.backgroundColor};

  :not([disabled]):hover {
    background: ${({ theme, buttonTheme }) => 
    buttonTheme
      ? buttonTheme.primaryButton.filled.hoverBackgroundColor
      : theme.sectionTheme
        ? theme.sectionTheme?.primaryButton?.filled?.hoverBackgroundColor
        : defaultSectionTheme.primaryButton?.filled?.hoverBackgroundColor};
    color: ${({ theme, buttonTheme }) => 
    buttonTheme
      ? buttonTheme.primaryButton.filled.hoverColor
      : theme.sectionTheme
        ? theme.sectionTheme?.primaryButton?.filled?.color
        : defaultSectionTheme.primaryButton?.filled?.color};
  }

  & > span {
    min-width: unset;
  }

  & > span > svg,
  & > svg {
    fill: ${({ theme, buttonTheme }) => 
    buttonTheme
      ? buttonTheme.primaryButton.text.color
      : theme.sectionTheme
        ? theme.sectionTheme?.primaryButton?.filled?.color
        : defaultSectionTheme.primaryButton?.filled?.color};

    & path {
      fill: ${({ theme, buttonTheme }) => 
    buttonTheme
      ? buttonTheme.primaryButton.text.color
      : theme.sectionTheme
        ? theme.sectionTheme?.primaryButton?.filled?.color
        : defaultSectionTheme.primaryButton?.filled?.color};
    }
  }
`

export const Button = styled(ButtonLink).attrs({
  as: 'button'
})``

export default { Button, ButtonLink }
