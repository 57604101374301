import { glossaryDataModify, relatedThumbnailDataModify } from '@/utils'
import { seoFields } from '../fragments'
import callContentful from './base.fetchers'
import { GlossaryProps } from '@/interfaces/glossary.interfaces'
import { CategoryTypeKeys } from '@/interfaces/category'
import { getBaseArticleQuery } from './thumbnail.fetchers'
import { ArticleProps } from '@/interfaces/article'
import { IGlossary, IGlossaryDirectory } from '@/interfaces/glossary'

type GetGlossaryContent = (arg: {
  isPreviewMode: boolean
  slug?: string
  locale: string
}) => Promise<GlossaryProps>

export const getGlossaryContent: GetGlossaryContent = async ({
  isPreviewMode,
  slug,
  locale,
}): Promise<GlossaryProps> => {
  const query = `
    {
      glossaryCollection(
        preview: ${isPreviewMode},
        where: {
          slug: "${slug}"
        },
        limit: 1,
        locale: "${locale}"
      ) {
        items {
          sys {
            id
          }
          title
          topics: topicsCollection(
            preview: ${isPreviewMode},
            limit: 3,
            locale: "${locale}"
          ) {
            items {
              sys {
                id
              }
              title
              pageSlug
            }
          }
          tags: tagsCollection(
            preview: ${isPreviewMode},
            limit: 7,
            locale: "${locale}"
          ) {
            items {
              sys {
                id
              }
              title
              pageSlug
            }
          }
          date
          content {
            json
            links {
              assets {
                block {
                  sys {
                    id
                  }
                  contentType
                  url
                  height
                  width
                  title
                  description
                }
              }
              entries {
                block {
                  sys {
                    id
                  }
                  __typename
                  ... on Quote {
                    quote
                    author
                  }
                }
              }
            }
          }
          seo {
            ...seoFields
          }
        }
      }
    }
    ${seoFields()}
  `

  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.glossaryCollection?.items[0]

  return glossaryDataModify(data)
}

type GetGlossarySmallContent = (arg: {
  isPreviewMode: boolean
  pageSlug?: string
  locale: string
}) => Promise<{
  sys: {
    id: string
  }
}>

export const getGlossarySmallContent: GetGlossarySmallContent = async ({
  isPreviewMode,
  pageSlug,
  locale,
}) => {
  const query = `
    {
      glossaryCollection(
        preview: ${isPreviewMode}
        where: { slug: "${pageSlug}" }
        locale: "${locale}"
        limit: 1
      ) {
        items {
          sys {
            id
          }
        }
      }
    }
  `

  const response = await callContentful(query, isPreviewMode)
  const data = response.data?.glossaryCollection?.items[0]
  return data
}

type GetRelatedArticles = (arg: {
  isPreviewMode: boolean
  categoryType?: CategoryTypeKeys
  tagSlug: string
  locale: string
}) => Promise<ArticleProps[] | null>

export const getRelatedArticles: GetRelatedArticles = async ({
  isPreviewMode,
  categoryType = CategoryTypeKeys.Tag,
  tagSlug,
  locale,
}) => {
  const query = `
    {
      categoryCollection(
        preview: ${isPreviewMode}
        locale: "${locale}"
        where: {
          type: "${categoryType}"
          pageSlug: "${tagSlug}"
        }
        limit: 1
      ) {
        items {
          title
          linkedFrom {
            ${getBaseArticleQuery(isPreviewMode, locale, undefined, '[date_DESC]')}
          }
        }
      }
    }
  `

  const response = await callContentful(query, isPreviewMode)
  const data = response?.data?.categoryCollection?.items?.[0]

  return relatedThumbnailDataModify(data) || null
}

export const getGlossaryDirectoryPage = async (): Promise<
  IGlossaryDirectory | undefined
> => {
  const query = /* GraphQL */ `
    query {
      fullPageCollection(where: { pageSlug: "/glossary" }, limit: 1) {
        items {
          sys {
            id
          }
          hideHeaderFooter
          sectionList: sectionListCollection(limit: 1) {
            items {
              __typename
              ... on Banner {
                theme
                uiTemplate
                header
                subCopy
                subCopyLong
                backgroundImage {
                  url(transform: { width: 743, resizeStrategy: FIT })
                  contentType
                }
              }
            }
          }
          seo {
            ...seoFields
          }
        }
      }
    }

    ${seoFields()}
  `

  const response = await callContentful(query, false)
  const glossaryDirectory = modifyGlossaryDirectoryData(
    response.data?.fullPageCollection?.items[0]
  )

  return glossaryDirectory
}

export const getGlossaryTerms = async (): Promise<IGlossary[]> => {
  const query = /* GraphQL */ `
    {
      glossaries: glossaryCollection(limit: 500, order: sys_publishedAt_DESC) {
        items {
          sys {
            id
            publishedAt
          }
          title
          slug
        }
      }
    }
  `

  const response = await callContentful(query, false)
  const glossaries = response.data.glossaries.items

  return glossaries
}

const modifyGlossaryDirectoryData = (data: any): IGlossaryDirectory => {
  return {
    ...data,
    sectionList: data?.sectionList?.items?.filter(Boolean) || [],
  }
}

export const getGlossarySlugs = async () => {
  const query = `
    {
      glossaryCollection(
        preview: false
        limit: 2700
      ) {
        items {
          slug
        }
      }
    }
  `

  const response = await callContentful(query, false)
  const data: string[] = response.data?.glossaryCollection?.items.map(
    ({ slug }: any) => slug
  );

  return data
}
