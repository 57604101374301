import React from 'react'
import { BannerProps } from '@/interfaces/banner'
import { useHighlightedSubCopySpring } from '../../hooks'
import {
  Header,
  HighlightedHeader,
  HighlightedWord,
  AnimatedWord,
  MobileBr,
} from './HeaderText.styles'
import HighlightedSpan from '../HighlightedSpan'

interface IHeaderTextProps {
  header: BannerProps['header']
  alignment: BannerProps['alignment']
  heroImages: BannerProps['heroImages']
  currentSubCopy?: string
  noMobileBr?: boolean
  isFirstItem: boolean
  centerOnMobile?: boolean
}

const HeaderTextComponent: React.FC<IHeaderTextProps> = ({
  header,
  alignment,
  heroImages,
  currentSubCopy,
  isFirstItem,
  noMobileBr,
  centerOnMobile,
}) => {
  if (!header) return null

  const allHeroImages = heroImages?.filter(Boolean)
  const isUsingMultipleHeroImages = allHeroImages?.length > 1
  const highlightedSubCopySpring = useHighlightedSubCopySpring(isFirstItem)
  const arrayOfHeaderText = header?.split('[[]]')

  const rotatingText = isUsingMultipleHeroImages && (
    <HighlightedHeader>
      {currentSubCopy?.split(' ').map((word, index) => (
        <HighlightedWord key={index}>
          <AnimatedWord style={highlightedSubCopySpring}>{word}</AnimatedWord>
          &nbsp;
        </HighlightedWord>
      ))}
      {!noMobileBr && <MobileBr />}
    </HighlightedHeader>
  )

  if (arrayOfHeaderText?.length < 2) {
    return (
      <Header alignment={alignment} centerOnMobile={centerOnMobile}>
        <HighlightedSpan text={header} />
        {!noMobileBr && header && <MobileBr />}
        {rotatingText}
      </Header>
    )
  }

  return (
    <Header alignment={alignment} centerOnMobile={centerOnMobile}>
      {arrayOfHeaderText
        ?.map((text) => text?.replace(/^\s+/g, ''))
        ?.map((text, index) =>
          index !== arrayOfHeaderText?.length - 1 ? (
            <React.Fragment key={index}>
              <HighlightedSpan text={text} />
              {!noMobileBr && text && <MobileBr />}
              {rotatingText}
            </React.Fragment>
          ) : (
            <React.Fragment key={index}>
              <HighlightedSpan text={text} />
            </React.Fragment>
          )
        )}
    </Header>
  )
}

export default HeaderTextComponent
