import type { SectionThemeKey } from './sectionThemeKey'
import type { Asset } from '@/interfaces/common'

export enum IconAlignment {
  Left = 'Left',
  Right = 'Right',
}

export interface ButtonProps {
  children?: any
  // TODO: replace with an enum ButtonVariant
  variant?: 'primary' | 'secondary' | 'link' | 'icon' | 'hyperlink'
  onClick?: () => void
  href?: string
  eventLabel?: string
  openInNewTab?: boolean
  // TODO: replace with an enum ButtonSize
  size?: 'small' | 'normal'
  // TODO: replace with an enum ButtonType
  type?: 'button' | 'submit' | 'reset'
  icon?: any
  disabled?: boolean
  style?: any
  className?: string
  buttonTheme?: SectionThemeKey
  iconAlignment?: IconAlignment
}
export interface NewButtonProps extends IButtonConfig {
  children?: React.ReactNode;
  onClick?: () => void;
  href?: string;
  eventLabel?: string;
  openInNewTab?: boolean;
  size?: 'small' | 'normal';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  className?: string;
  iconAlignment?: IconAlignment;
}

// Contentful
export interface IButtonConfig {
  buttonVariant?: 'Outlined' | 'Filled' | 'Text';
  buttonColor?: 'Primary' | 'Secondary' | 'Tertiary';
  buttonIcon?: Asset;
  buttonIconPosition?: 'Left' | 'Right';
}