import { PageThemeKey } from '@/interfaces/pageTheme.interfaces'
import PAGE_THEME from '@/constants/pageTheme.constants'

type PageTheme = typeof PAGE_THEME.Checkout
type GetPageTheme = (theme?: PageThemeKey) => PageTheme | undefined

export const getPageTheme: GetPageTheme = (theme) => {
  switch (theme) {
    case PageThemeKey.PostPurchase:
      return PAGE_THEME.PostPurchase
    case PageThemeKey.Checkout:
      return PAGE_THEME.Checkout
    case PageThemeKey.Returns:
      return PAGE_THEME.Returns
    case PageThemeKey.Logistics:
      return PAGE_THEME.Logistics
    case PageThemeKey.CoPilot:
      return PAGE_THEME.CoPilot
    default:
      return
  }
}
